import { Container, Typography } from '@mui/material';
import MainDashboard from 'components/MainDashboard';
import NavBar from 'components/NavBar';
import { useAuthContextState, useAuthContext } from 'contexts/Auth';
import { BusinessIcon, UserCircleIcon, LogoutIcon } from 'utils/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { signOutUser } from 'contexts/Auth/actions';
import signOut from 'helpers/signOut';
import SidePanelMenu from 'components/SidePanelMenu';
import styles from './dashboard.module.css';

const Dashboard = () => {
  const authState = useAuthContextState();

  const [_authState, authDispatch] = useAuthContext();
  const location = useLocation();
  const history = useHistory();

  // console.log('authState', authState);
  return (
    <div>
      <NavBar Title="Usage Statistcs" />
      <div className={styles.dashboardRoot}>
        <SidePanelMenu />
        <div className={styles.rightPanel}>
          <div className={styles.mainDashboard}>
            <MainDashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
