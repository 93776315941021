// import signOut from 'helpers/signOut';
import signOut from 'helpers/signOut';
import { useAuthContext } from '../../contexts/Auth/index';
import styles from './navbar.module.css';
import { LogoutIcon } from '../../utils/icons';

const { AppBar, Toolbar } = require('@mui/material');

const NavBar = ({ Title }) => {
  const [_authState, authDispatch] = useAuthContext();

  return (
    <AppBar position="fixed" className={styles.navBar}>
      <Toolbar>
        <div className={styles.navBarItems}>
          <div>
            <img src="/main_logo.png" alt="Digifarm" className={styles.logo} />
          </div>
          <div
            className={styles.menuItem}
            onClick={async () => {
              await signOut(authDispatch);
            }}
            aria-hidden="true"
          >
            <LogoutIcon className="icon-root" />
            <div className={styles.logoutLabel}>Log Out</div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
