import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './TokenDashboard.module.css';

const TokenDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className={styles.header}>
        <Typography
          className={styles.tokenLabelText}
          variant="h5"
          fontWeight={700}
        >
          Token Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={styles.addTokenButton}
        >
          Add New Token
        </Button>
      </div>
      {!isLoading ? (
        <div className={styles.tableContainer}>
          <table className={styles.dashboardTable}>
            <thead className={styles.tableTitle}>
              <tr>
                <th>Token ID</th>
                <th>Type</th>
                {/* <th>Created At</th>
                <th>Last Used</th> */}
                <th>Status</th>
                <th>.</th>
              </tr>
            </thead>
            <tbody className={styles.tableItems}>
              <tr>
                <td>****d4f6</td>
                <td>
                  <div className={styles.typeCell}>Demo (Free)</div>
                </td>
                {/* <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>20:36:23</div>
                  </div>
                </td>
                <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>Name Surname</div>
                  </div>
                </td> */}
                <td>
                  <div className={styles.statusCell}>Active</div>
                </td>
                <td>...</td>
              </tr>
              <tr>
                <td>****d4f6</td>
                <td>
                  <div className={styles.typeCell}>Demo (Free)</div>
                </td>
                {/* <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>20:36:23</div>
                  </div>
                </td>
                <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>Name Surname</div>
                  </div>
                </td> */}
                <td>
                  <div className={styles.statusCell}>Active</div>
                </td>
                <td>...</td>
              </tr>
              <tr>
                <td>****d4f6</td>
                <td>
                  <div className={styles.typeCell}>Demo (Free)</div>
                </td>
                {/* <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>20:36:23</div>
                  </div>
                </td>
                <td>
                  <div className={styles.dateCell}>
                    <div className={styles.dateTop}>Jan 01, 2022</div>
                    <div className={styles.dateBottom}>Name Surname</div>
                  </div>
                </td> */}
                <td>
                  <div className={styles.statusCell}>Active</div>
                </td>
                <td>...</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.usageCircularProgress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default TokenDashboard;
