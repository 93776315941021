/* eslint-disable react/no-unknown-property */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function GlobeIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 11.9998H7.172C8.734 11.9998 10 10.7338 10 9.1718V9.1718C10 8.4218 9.702 7.7018 9.172 7.1718L6.717 4.7168"
          stroke="#01B075"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLineJoin="round"
        />
        <path
          d="M18.2921 18.438L16.5151 15.773C16.1931 15.29 15.6521 15 15.0721 15V15C14.4151 15 13.8151 14.629 13.5211 14.041L13.3881 13.775C13.1441 13.287 13.1441 12.712 13.3881 12.224L14.5211 9.958C14.8141 9.371 15.4151 9 16.0721 9H20.4801"
          stroke="#01B075"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLineJoin="round"
        />
        <path
          d="M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21"
          stroke="#01B075"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLineJoin="round"
        />
        <path
          d="M12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3"
          stroke="#01B075"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLineJoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

function PerformanceIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2 19H22"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 19V14"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 19V10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 19V5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 19V12"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function LineGlobeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#1D2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12H22"
        stroke="#1D2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
        stroke="#1D2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function StarIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 2.41406L12.4455 7.40823L17.9163 8.21406L13.958 12.0991L14.8922 17.5874L9.99967 14.9949L5.10717 17.5874L6.04134 12.0991L2.08301 8.21406L7.55301 7.40823L9.99967 2.41406Z"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
function InfoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.999 8C11.861 8 11.749 8.112 11.75 8.25C11.75 8.388 11.862 8.5 12 8.5C12.138 8.5 12.25 8.388 12.25 8.25C12.25 8.112 12.138 8 11.999 8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V17"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
function PointerIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.3388 16.143V16.143L20.6221 18.4262C21.126 18.9299 21.126 19.748 20.6221 20.2518L19.2517 21.6222C18.7479 22.1259 17.9297 22.1259 17.4259 21.6222L15.1426 19.339L13.422 21.0595C13.0822 21.3992 12.5045 21.2628 12.3531 20.8076L9.03439 10.8509C8.86568 10.346 9.34638 9.86649 9.85136 10.034L19.8086 13.3525C20.2639 13.5039 20.4003 14.0816 20.0605 14.4213L18.3388 16.143"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78 10.9297H2.5"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.43982 6.94031L4.81982 5.32031"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.39979 14.96L4.77979 16.58"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4302 5.28V3"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.46 6.90027L16.08 5.28027"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
function BBoxIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5 7V17"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 17V7"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 21H18C17.448 21 17 20.552 17 20V18C17 17.448 17.448 17 18 17H20C20.552 17 21 17.448 21 18V20C21 20.552 20.552 21 20 21Z"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 21H4C3.448 21 3 20.552 3 20V18C3 17.448 3.448 17 4 17H6C6.552 17 7 17.448 7 18V20C7 20.552 6.552 21 6 21Z"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7H18C17.448 7 17 6.552 17 6V4C17 3.448 17.448 3 18 3H20C20.552 3 21 3.448 21 4V6C21 6.552 20.552 7 20 7Z"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7H4C3.448 7 3 6.552 3 6V4C3 3.448 3.448 3 4 3H6C6.552 3 7 3.448 7 4V6C7 6.552 6.552 7 6 7Z"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 19H17"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 5H7"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function DocumentationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 6H18"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3H16"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21V18C17 17.448 17.448 17 18 17H21"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.586 20.414L20.414 18.586C20.789 18.211 21 17.702 21 17.172V11C21 9.895 20.105 9 19 9H5C3.895 9 3 9.895 3 11V19C3 20.105 3.895 21 5 21H17.172C17.702 21 18.211 20.789 18.586 20.414Z"
        stroke="#01B075"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function CoverageIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0035 3.2999C15.5003 3.2999 15.9035 3.7031 15.8999 4.1999C15.8999 4.6967 15.4967 5.0999 14.9999 5.0999C14.5031 5.0999 14.0999 4.6967 14.0999 4.1999C14.0999 3.7031 14.5031 3.2999 15.0035 3.2999Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9998 10.001C14.9998 10.001 18.5018 7.12598 18.5018 4.50098C18.5018 2.56798 16.9338 1.00098 14.9998 1.00098C13.0658 1.00098 11.4978 2.56798 11.4978 4.50098C11.4978 7.12598 14.9998 10.001 14.9998 10.001Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.531 4.0249C6.78 4.2709 3 8.1879 3 12.9999C3 17.9709 7.029 21.9999 12 21.9999C16.971 21.9999 21 17.9709 21 12.9999C21 10.3479 19.848 7.9719 18.023 6.3289"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99976 19.7994L7.99995 20.9971C7.99995 18.9971 12 19.2331 12 16.9971C12 14.9971 8.99995 14.9971 8.99995 11.9971C8.99995 9.16907 6.99995 8.99707 3.99995 8.99707L2.99976 15.5994"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9699 19.72C17.8189 19.662 17.6689 19.601 17.5259 19.517C16.0899 18.677 15.6079 16.833 16.4469 15.397C17.2869 13.961 19.1309 13.479 20.5669 14.318C20.6729 14.38 20.7619 14.458 20.8579 14.53"
        fill="white"
      />
    </SvgIcon>
  );
}
function MarkerIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 17.5C10.0003 17.5 4.16699 12.7083 4.16699 8.33333C4.16699 5.11167 6.77866 2.5 10.0003 2.5C13.222 2.5 15.8337 5.11167 15.8337 8.33333C15.8337 12.7083 10.0003 17.5 10.0003 17.5Z"
        fill="#99DFC8"
        stroke="#01B075"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.8333C8.61917 10.8333 7.5 9.71409 7.5 8.33325C7.5 6.95242 8.61917 5.83325 10 5.83325C11.3808 5.83325 12.5 6.95242 12.5 8.33325C12.5 9.71409 11.3808 10.8333 10 10.8333Z"
        fill="white"
        stroke="#01B075"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
function MarkerIconOrange(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 17.5C10.0003 17.5 4.16699 12.7083 4.16699 8.33333C4.16699 5.11167 6.77866 2.5 10.0003 2.5C13.222 2.5 15.8337 5.11167 15.8337 8.33333C15.8337 12.7083 10.0003 17.5 10.0003 17.5Z"
        fill="#F0B472"
        stroke="#EA891D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.8333C8.61917 10.8333 7.5 9.71409 7.5 8.33325C7.5 6.95242 8.61917 5.83325 10 5.83325C11.3808 5.83325 12.5 6.95242 12.5 8.33325C12.5 9.71409 11.3808 10.8333 10 10.8333Z"
        fill="white"
        stroke="#EA891D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function GlobeFinanceIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.5 11.25L3 9.75L4.5 11.25"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 12.75L21 14.25L19.5 12.75"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67822 6.79C6.31022 4.501 8.97522 3 12.0002 3C16.9702 3 21.0002 7.03 21.0002 12C21.0002 12.684 20.9172 13.348 20.7732 13.988"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3222 6.78998C17.0202 7.56398 14.5632 7.99998 12.0002 7.99998C9.43722 7.99998 6.98022 7.56398 4.67822 6.78998"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3181 17.206C17.6871 19.495 15.0221 20.996 11.9961 20.996C7.02609 20.996 2.99609 16.966 2.99609 11.996C2.99609 11.312 3.07909 10.648 3.22309 10.008"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6748 17.206C6.9768 16.432 9.4338 15.996 11.9968 15.996C14.5598 15.996 17.0168 16.432 19.3188 17.206"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C14.0711 21 15.75 16.9706 15.75 12C15.75 7.02944 14.0711 3 12 3C9.92893 3 8.25 7.02944 8.25 12C8.25 16.9706 9.92893 21 12 21Z"
        stroke="#EA891D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function BackArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.01001 11.98H19"
        stroke="#1D2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.013 5.98779L4.00195 11.9998L10.013 18.0118"
        stroke="#1D2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 6L18 18"
        stroke="#848282ce"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6L6 18"
        stroke="#848282ce"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function SearchBar(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"
        stroke="#848282ce"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function CompassIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.24 7.76L14.12 14.12L7.76 16.24L9.88 9.88L16.24 7.76Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L12 12.01"
        stroke="#8a8383"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export {
  CompassIcon,
  GlobeIcon,
  PerformanceIcon,
  LineGlobeIcon,
  StarIcon,
  InfoIcon,
  PointerIcon,
  BBoxIcon,
  DocumentationIcon,
  CoverageIcon,
  MarkerIcon,
  MarkerIconOrange,
  GlobeFinanceIcon,
  BackArrowIcon,
  CloseIcon,
  SearchIcon,
  SearchBar,
};
