import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ClearIcon from '@mui/icons-material/Clear';
import ReactJson from 'react-json-view';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import for next arrow
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // Import for previous arrow
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

// Styles for the modal and GeoJSON structure
const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '100%',
    maxWidth: '80%',
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  geojsonStructure: {
    maxHeight: 'calc(80% - 100px)',
    overflowY: 'auto',
    backgroundColor: '#f8f9fa',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '15px 20px 15px 15px',
    marginBottom: '20px',
    fontFamily: 'monospace',
    fontSize: '14px',
    textAlign: 'left',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    width: '600px', // Fixed width
    height: '500px', // Fixed height, adjust as needed
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructions: {
    marginBottom: '20px',
    marginLeft: '20px',
  },
  instructionHeader: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  instruction: {
    fontSize: '12px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  instructionContainer: {
    marginTop: '10px',
  },
  instructionHover: {
    color: 'blue',
    textDecoration: 'underline',
  },
  arrowButton: {
    cursor: 'pointer',
    margin: '0 10px',
    fontSize: '50px',
    color: '#01B075',
  },
};

const GeoJSONInstructionModal = ({ open, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copied, setCopied] = useState(false);

  const geoJSONStructures = [
    {
      id: 'FeatureCollectionPolygon',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: [[[], [], [], []]],
              type: 'Polygon',
            },
          },
        ],
      },
      instructions: (
        <div style={{ ...styles.instructions, ...styles.instructionContainer }}>
          <p style={styles.instructionHeader}>
            Instructions for{' '}
            <code style={{ color: 'red' }}>Polygon FeatureCollection</code>
          </p>
          <p style={styles.instruction}>
            Coordinates length should always be one.
          </p>
          <p style={styles.instruction}>
            There should not be more than one feature.
          </p>
          <p style={styles.instruction}>
            There should always be only one geometry type.
          </p>
        </div>
      ),
    },
    // Define other structures here in the same format
    {
      id: 'SingleFeaturePolygon',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[[], [], [], []]],
        },
      },
      instructions: (
        <div style={{ ...styles.instructions, ...styles.instructionContainer }}>
          <p style={styles.instructionHeader}>
            Instructions for{' '}
            <code style={{ color: 'red' }}> Polygon Feature </code>
          </p>
          <p style={styles.instruction}>
            Coordinates length should always be one.
          </p>
          <p style={styles.instruction}>
            There should not be more than one feature.
          </p>
          <p style={styles.instruction}>
            There should always be only one geometry type.
          </p>
        </div>
      ),
    },
    {
      id: 'FeatureCollectionMulti',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: [[[[], [], [], []]]],
              type: 'MultiPolygon',
            },
          },
        ],
      },
      instructions: (
        <div style={{ ...styles.instructions, ...styles.instructionContainer }}>
          <p style={styles.instructionHeader}>
            Instructions for{' '}
            <code style={{ color: 'red' }}>MultiPolygon FeatureCollection</code>
          </p>
          <p style={styles.instruction}>
            Coordinates length should always be one and should be in the
            provided format.
          </p>
          <p style={styles.instruction}>
            There should not be more than one feature.
          </p>
          <p style={styles.instruction}>
            There should always be only one geometry type.
          </p>
        </div>
      ),
    },
    {
      id: 'SingleFeatureMulti',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[[[], [], [], []]]],
        },
      },
      instructions: (
        <div style={{ ...styles.instructions, ...styles.instructionContainer }}>
          <p style={styles.instructionHeader}>
            Instructions for
            <code style={{ color: 'red' }}> MultiPolygon Feature</code>
          </p>
          <p style={styles.instruction}>
            Coordinates length should always be one and should be in the
            provided format.
          </p>
          <p style={styles.instruction}>
            There should not be more than one feature.
          </p>
          <p style={styles.instruction}>
            There should always be only one geometry type.
          </p>
        </div>
      ),
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % geoJSONStructures.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + geoJSONStructures.length) % geoJSONStructures.length
    );
  };

  const selectedStructure = geoJSONStructures[currentIndex].data;
  const selectedInstructions = geoJSONStructures[currentIndex].instructions;

  const copyToClipboard = () => {
    const formattedGeoJSON = JSON.stringify(selectedStructure, null, 2);
    navigator.clipboard
      .writeText(formattedGeoJSON)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error('Could not copy text: ', err));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="geojson-instruction-modal"
      aria-describedby="geojson-instruction-description"
    >
      <div style={styles.modalContainer}>
        <Typography variant="h6" id="modal-title">
          Instructions for valid GeoJSON Format Examples
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContenttems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <ArrowCircleLeftIcon
            style={styles.arrowButton}
            onClick={handlePrevious}
          />
          <div style={styles.geojsonStructure}>
            <ReactJson
              src={selectedStructure}
              theme="rjv-default"
              name={null}
              displayDataTypes={false}
              indentWidth={4}
              iconStyle="square"
            />
          </div>
          <ArrowCircleRightIcon
            style={styles.arrowButton}
            onClick={handleNext}
          />
        </div>
        {selectedInstructions}
        <div style={styles.instructions}>
          <Typography variant="body2" id="geojson-instruction-description">
            We have only enabled <code style={{ color: 'red' }}>Polygon</code>{' '}
            and <code style={{ color: 'red' }}>MultiPolygon</code> single
            geometry GeoJson functionality for our application.
          </Typography>
        </div>
        <Button
          onClick={copyToClipboard}
          variant="contained"
          color="primary"
          startIcon={<FileCopyIcon />}
          style={{ marginRight: '10px' }}
        >
          {copied ? 'Copied!' : 'Copy'}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          endIcon={<ClearIcon />}
          style={{ marginLeft: '10px' }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default GeoJSONInstructionModal;
