import { Drawer, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { store } from 'contexts/Map/store';
import { setAPI } from 'contexts/Map/action';
import { makeStyles } from '@mui/styles';
import PartialDRBBoxDrawer from './PartialDRBBoxDrawer';

const useStyles = makeStyles({
  drawer: {
    width: 560,
    '@media (max-width: 600px)': {
      width: 'unset',
      maxHeight: 300,
      overflowY: 'scroll',
    },
  },
  root: {
    width: 560,
    '@media (max-width: 600px)': {
      width: 'unset',
      maxHeight: 300,
    },
    backgroundColor: '#fff',
    paddingBottom: '100px',
    fontSize: 14,
    '& h3': {
      fontSize: 20,
      lineHeight: 1.2,
      margin: 0,
    },
  },
  info: {
    padding: '24px 20px',
    boxSizing: 'border-box',
    color: '#949EA7',
    backgroundColor: '#fff',
    marginBottom: 8,
    '& h3': {
      lineHeight: '24px',
      marginBottom: 8,
      color: '#1D2023',
    },
    '& h4': {
      color: '#1D2023',
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
      margin: 0,
    },
    '& h6': {
      color: '#1D2023',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      margin: 0,
    },
  },

  flex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
  },
  inputRoot: {
    borderRadius: 4,
  },
  dropdownContainer: {
    backgroundColor: '#fff',
  },
  select: {
    width: '100%',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 700,
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1em',
  },
  selectInputRoot: {
    '&::before': {
      borderBottomColor: '#EEEFF1',
    },
  },
});

const RightDrawer = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;

  const {
    state: { mode, drawMode: mapDrawMode, dataLayer },
    dispatch,
  } = useContext(store);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer
      anchor={windowSize < 600 ? 'bottom' : 'right'}
      open={open}
      onClose={onClose}
      ModalProps={{ BackdropProps: { invisible: true } }}
      variant="persistent"
      className={open ? 'right-side-drawer open' : 'right-side-drawer'}
      PaperProps={{
        style: {
          top: '64px',
        },
      }}
    >
      <div className={classes.root}>
        <div className={classes.dropdownContainer}>
          {/* <Select
            id="right-drawer-mode-select"
            classes={{
              root: classes.select,
              icon: classes.selectIcon,
              outlined: classes.outlined,
            }}
            value={dataLayer.api}
            onChange={(event) => {
              console.log(event.target.value, 'setting select');
              dispatch({
                type: setAPI,
                payload: {
                  api: event.target.value,
                  drawMode: 'draw_rectangle',
                },
              });
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            fullWidth
          >
            {dataLayer.type !== 'partialDR' && (
              <MenuItem value="coverage">Query Coverage area</MenuItem>
            )}

            {dataLayer.type === 'partialDR' && (
              <MenuItem value="bbox">Select AoI</MenuItem>
            )}
          </Select> */}
          {/* </FormControl> */}
        </div>
        <PartialDRBBoxDrawer />
      </div>
    </Drawer>
  );
};

export default RightDrawer;
