import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

const DownloadButton = ({ url, fileName, disabled }) => (
  <a href={url} download={fileName}>
    <Button
      variant="contained"
      startIcon={<DownloadIcon />}
      disabled={disabled}
    >
      Download
    </Button>
  </a>
);

export default DownloadButton;
