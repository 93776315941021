import {
  setCredit,
  setCreditError,
  setCreditLoadingState,
  resetCreditError,
} from './action';

const creditReducer = (state, { type, payload }) => {
  switch (type) {
    case setCredit:
      return {
        ...state,
        organizationId: payload?.organizationId,
        expiryDate: payload?.expiryDate,
        availableCredits: payload?.availableCredits,
        totalCredits: payload?.totalCredits,
      };

    case setCreditLoadingState:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case setCreditError:
      return {
        ...state,
        error: payload.error,
      };

    case resetCreditError:
      return {
        ...state,
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default creditReducer;
