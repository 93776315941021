import { datadogRum } from '@datadog/browser-rum';

export default function datadogInit() {
  const datadogAppID = process.env.REACT_APP_DATADOG_APP_ID;
  const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const datadogService = process.env.REACT_APP_DATADOG_SERVICE;
  const stage = process.env.REACT_APP_STAGE;

  if (datadogAppID && datadogClientToken && datadogService) {
    datadogRum.init({
      applicationId: datadogAppID,
      clientToken: datadogClientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: datadogService,
      env: stage,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
