import { getCurrentSession } from '../api/cognito';

export default {
  Auth: {
    region: process.env.REACT_APP_AUTH_region,
    userPoolId: process.env.REACT_APP_AUTH_userPoolId,
    userPoolWebClientId: process.env.REACT_APP_AUTH_userWebClientId,
  },
  API: {
    endpoints: [
      {
        name: 'public',
        endpoint: process.env.REACT_APP_API_endpoint,
        custom_header: async () => ({
          Authorization: (await getCurrentSession()).idToken.jwtToken,
        }),
      },
      {
        name: 'partialDR',
        endpoint: process.env.REACT_APP_PARTIAL_DR_endpoint,
        custom_header: async () => ({
          Authorization: (await getCurrentSession()).idToken.jwtToken,
        }),
      },
    ],
  },
};
