import TokenDashboard from 'components/TokenDashboard';
import NavBar from 'components/NavBar';
import SidePanelMenu from 'components/SidePanelMenu';
import styles from './token-dashboard.module.css';

const Dashboard = () => (
  <div>
    <NavBar Title="Usage Statistcs" />
    <div className={styles.dashboardRoot}>
      <SidePanelMenu />
      <div className={styles.rightPanel}>
        <div className={styles.tokenDashboard}>
          <TokenDashboard />
        </div>
      </div>
    </div>
  </div>
);

export default Dashboard;
