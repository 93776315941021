import React, { useEffect } from 'react';

const ChatFlow = () => {
  useEffect(() => {
    // Load the HubSpot ChatFlow script
    const script = document.createElement('script');
    script.src = '//js-na1.hs-scripts.com/7913942.js'; // Load the HubSpot ChatFlow script
    script.id = 'hs-script-loader'; // Assign an ID to the script tag
    script.async = true;
    script.defer = true; // Add the async and defer attributes for better performance
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      const scriptElement = document.getElementById('hs-script-loader');
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  return null; // Return null because this component doesn't render any visible content
};

export default ChatFlow;
