/* eslint-disable no-underscore-dangle */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  Button,
  IconButton,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

// import SinglePointMode from './modes/draw_single_point';

import { makeStyles } from '@mui/styles';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { store } from 'contexts/Map/store';

import * as turf from '@turf/turf';

import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import DrawAssistedRectangle from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode';
import tilebelt from '@mapbox/tilebelt';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  // LineGlobeIcon,
} from '@mui/icons-material';

import {
  addBaseMapLayers,
  addPlaceLabelLayers,
  mapStyle,
  turnOffBaseMapLayers,
  turnOnBaseMapLayers,
  getTilesForCurrentBounds,
  locationObj,
} from 'helpers/mapUtils';
import MAXZOOM from 'constants/map-constants';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  setPoint,
  setPolygon,
  setTile,
  setFieldId,
  setTiles,
  setAPI,
  setDrawMode,
  setMGRSTileExceptionPolygon,
  setDRResult,
} from 'contexts/Map/action';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {
  LineGlobeIcon,
  BBoxIcon,
  CompassIcon,
  SearchIcon,
  SearchBar,
  CloseIcon,
} from 'icons';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2lyaXNocGFsbGFnYXR0aSIsImEiOiJja2h6NGVuc20wYndjMnlxaDExbzlsa3B2In0.e94ZfcoPleAA2nqjeX10ag';
// 'pk.eyJ1Ijoicm9oaXRzaGV0dHkwMCIsImEiOiJjanpmOXVoOXowOXF6M2lsdG95NmF5dWEzIn0.yuI5HCaw8Ka2-hMQM5RG4A';

let heightOfTheWindow = window.innerHeight;

window.addEventListener('resize', () => {
  heightOfTheWindow = window.innerHeight;
});

const useStyles = makeStyles({
  hoverDisplayContainer: {
    position: 'absolute',
    left: 60,
    top: 0,
    zIndex: 1,
    minHeight: 220,
    paddingLeft: 8,
    minWidth: 160,
    textAlign: 'left',
    color: '#fff',
  },
  shadowGenerator: {
    padding: 30,
    zIndex: 1,
    position: 'relative',
    top: -60,
    boxShadow: '-54px 72px 138px 62px rgba(0,0,0,0.85)',
  },
  hoverContent: {
    position: 'relative',
    zIndex: 2,
  },
  globeIconContainer: {
    position: 'absolute',
    right: 8,
    bottom: 12,
    zIndex: 1,
    height: 48,
    width: 48,
    '@media (max-width: 600px)': {
      bottom: 56,
    },
  },
  globebutton: {
    width: 48,
    height: 48,
  },
  checkboxContainer: {
    position: 'absolute',
    left: 8,
    bottom: 38,
    zIndex: 1000,
    color: 'black',
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
    '& .MuiCheckbox-root': {
      color: '#06b075', // DigiFarm green
      '&.Mui-checked': {
        color: '#06b075', // DigiFarm green
      },
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'white', // Color of the check icon
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  iconRoot: {
    fill: 'none',
  },
  clearButtonContainer: {
    position: 'absolute',
    right: 60,
    bottom: 12,
    // display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
  },
  clearButton: {
    backgroundColor: '#1D2023',
    fontSize: 14,
  },
  drawerToggleContainer: {
    position: 'absolute',
    right: 8,
    top: '40vh',
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  collapseDrawerButton: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
    '&:hover': {
      color: '#999',
    },
  },
  collapseDrawerButtonMobile: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
  },
  drawerToggleMobileOpen: {
    position: 'absolute',
    right: 68,
    top: heightOfTheWindow - 340,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
  drawerToggleMobileClosed: {
    position: 'absolute',
    right: 68,
    bottom: 64,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
  searchBarContainer: {
    position: 'absolute',
    bottom: 172,
    right: 8,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
  },
  searchFormContainer: {
    overflow: 'hidden',
    transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'center',
  },
  searchFormContainerOpen: {
    width: '500px', // Adjust the width as needed
    height: '80px',
  },
  searchForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
    padding: '10px 0',
  },
  formControl: {
    marginRight: 10,
    flex: 1,
    height: '40px',
    bottom: '0.5em',
    backgroundColor: '#fff',
    color: 'primary',
  },
});

const DrawContext = createContext();
const MapContext = createContext();

export const useDrawContext = () => useContext(DrawContext);
console.log('useDrawContext: ', useDrawContext);
export const useMapContext = () => useContext(MapContext);

export const DrawProvider = ({ children }) => {
  const [draw, setDraw] = useState(null); // Initialize draw state

  return (
    <DrawContext.Provider value={{ draw, setDraw }}>
      {children}
    </DrawContext.Provider>
  );
};

export const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null);

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const {
    state: {
      mode,
      // drawMode: mapDrawMode,
      feature,
      point,
      polygon,
      location,
      dataLayer,
      tileURL,
      tiles,
      tileUrlCounter,
      tileBounds,
      viewBounds,
      fieldId,
      mgrsExceptionPolygon,
    },
    dispatch,
  } = useContext(store);

  const classes = useStyles();

  const { zoom, setZoom, toggleRightDrawer, isRightDrawerOpen } = props;

  const [lng, setLng] = useState(11.1804734);
  const [lat, setLat] = useState(60.746268);

  const { draw, setDraw } = useDrawContext();

  const { map, setMap } = useMapContext();

  const modeRef = useRef(null);
  const drawModeRef = useRef(null);
  const [mapDrawMode, setMapDrawMode] = useState(dataLayer.drawMode);
  const [showClearButton, setShowClearButton] = useState(false);

  const [fieldIdLocal, setFieldIdLocal] = useState('');
  const [basemap, setBasemap] = useState('satellite-streets-v11');

  const [mgrsCheckbox, setMGRSCheckbox] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchValues, setSearchValues] = useState({
    longitude: '',
    latitude: '',
  });

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues({ ...searchValues, [name]: value });
  };

  const handleCoordinateSubmit = (e) => {
    // const long = parseFloat(e.target.longitude.value);
    // const lati = parseFloat(e.target.latitude.value);

    const long = parseFloat(searchValues.longitude);
    const lati = parseFloat(searchValues.latitude);

    if (!Number.isNaN(long) && !Number.isNaN(lati) && map) {
      map.flyTo({
        center: [long, lati],
        zoom: 14, // Adjust zoom level as needed
      });
      if (map.getSource('point')) {
        map.removeLayer('point');
        map.removeSource('point');
      }

      if (map.getSource('point')) {
        map.getSource('point').setData({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [long, lati],
          },
        });
      } else {
        map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [long, lati],
            },
          },
        });

        map.addLayer({
          id: 'point',
          type: 'circle',
          source: 'point',
          paint: {
            'circle-radius': 14,
            'circle-color': '#088',
          },
        });
      }
      setShowClearButton(true);
    }
  };

  const handleSearchSubmit = () => {
    // e.preventDefault();
    if (searchValues.longitude && searchValues.latitude) {
      handleCoordinateSubmit();
      // setIsSearchBarVisible(false);
      // setSearchValues({
      //   longitude: '',
      //   latitude: '',
      // });
    }
  };

  const handleSearchClose = () => {
    setSearchValues({
      longitude: '',
      latitude: '',
    });
  };

  const handleCheckboxChange = (event) => {
    setMGRSCheckbox(event.target.checked);

    if (event.target.checked && map) {
      if (!map.getSource('mgrs_tiles')) {
        map.addSource('mgrs_tiles', {
          type: 'vector',
          tiles: [
            'https://vectors.digifarm.io/v1/vectors/test/test/{z}/{x}/{y}.mvt?table=_mgrs&showProps=true&limit=2000',
          ],
        });

        map.addLayer({
          id: 'mgrs_polygons',
          type: 'fill',
          source: 'mgrs_tiles',
          'source-layer': 'test',
          paint: {
            'fill-color': 'rgba(225, 225, 225, 0.15)',
            'fill-opacity': [
              'step',
              ['zoom'],
              1, // fill opacity at zoom levels less than 9
              9, // starting at zoom level 9
              0, // fill opacity is 0
            ],
          },
        });

        map.addLayer({
          id: 'mgrs_lines',
          type: 'line',
          source: 'mgrs_tiles',
          'source-layer': 'test',
          paint: {
            'line-color': '#fff', // white line color
            'line-width': [
              'step',
              ['zoom'],
              0, // line width at zoom levels less than 9
              9, // starting at zoom level 9
              1, // line width is 1
            ],
          },
        });
        map.addLayer({
          id: 'mgrs_tiles_labels',
          type: 'symbol',
          source: 'mgrs_tiles',
          'source-layer': 'test', // replace with your source layer name
          layout: {
            'text-field': ['get', 'name'], // access the 'name' property from your data
            'text-size': [
              'step',
              ['zoom'],
              7,
              4,
              10, // text size at zoom levels less than 5
              5, // starting at zoom level 5
              13, // text size is 13
              9, // starting at zoom level 9
              17, // text size is 17
            ],
            'text-justify': 'center', // center the text
            'text-anchor': 'center', // anchor the text in the center
            'text-font': ['Open Sans Bold'], // set the font to bold
          },
          paint: {
            'text-color': '#fff', // set the text color to white
          },
        });
      }
    } else if (!event.target.checked && map) {
      if (map.getSource('mgrs_tiles')) {
        map.removeLayer('mgrs_tiles_labels');
        map.removeLayer('mgrs_polygons');
        map.removeLayer('mgrs_lines');
        map.removeSource('mgrs_tiles');
      }
    }
  };

  let onCreateListener;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const dataLayermapDrawMode = dataLayer.drawMode;

    // if (map && mapDrawMode && drawModeRef.current !== mapDrawMode) {
    if (map && dataLayermapDrawMode) {
      // console.log('adding draw');
      // console.log('ee', dataLayermapDrawMode);
      const Draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          // draw_single_point: SinglePointMode,
          draw_rectangle: DrawRectangle,
          ...MapboxDraw.modes,
        },
      });

      map.addControl(Draw, 'top-left');

      Draw.changeMode(dataLayermapDrawMode);
      setDraw(Draw);
      drawModeRef.current = dataLayermapDrawMode;
      return () => {
        // console.log('Removing');
        map.removeControl(Draw);
      };
    }
    if (dataLayermapDrawMode !== 'draw_rectangle') {
      setShowClearButton(false);
    }
  }, [dataLayer.drawMode, map, drawModeRef]);

  useEffect(() => {
    // Can try using modeRef as well - if that makes this better

    if (dataLayer.api === 'bbox' || dataLayer.api === 'lowResBBOX') {
      try {
        if (draw) {
          draw.deleteAll();
          draw.changeMode(dataLayer.drawMode);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (dataLayer.api === 'lowResTile' && map) {
      map.on('click', (e) => {
        // console.log(e);
        const coord = mapboxgl.MercatorCoordinate.fromLngLat(e.lngLat, 0);
        // console.log(coord);
        dispatch({
          type: setTile,
          payload: {
            tile: tilebelt.pointToTile(
              e.lngLat.lng,
              e.lngLat.lat,
              Math.round(map.getZoom()) + 1
            ),
          },
        });
      });
    }

    // This doesn't work. The else if condition should be executed only when the previous api was coverage
    if (dataLayer.api === 'coverage' && map) {
      // console.log('fly to 2');
      // addPlaceLabelLayers(map);
      map.flyTo({ center: [20.6, 34.1], zoom: 1.4 });
    } else if (map) {
      // console.log('fly to 1', location);
      // removePlaceLabelLayers(map);

      // map.flyTo({
      //   center: Object.values(locationObj[dataLayer.type])[0].center,
      //   zoom: Object.values(locationObj[dataLayer.type])[0].zoom,
      // }))
      const defaultLocation = Object.values(locationObj[dataLayer.type])[0];
      // console.log('default location of the dataLayer', defaultLocation.center);
      if (map.getZoom < 9) {
        if (location) {
          map.flyTo({
            center: location.center,
            // eslint-disable-next-line no-nested-ternary
            zoom: location.zoom
              ? location.zoom
              : mode === 'draw_coverage'
              ? 7
              : 14,
          });
        } else {
          map.flyTo({
            center: defaultLocation.center,
            zoom: defaultLocation.zoom ? defaultLocation.zoom : 14,
          });
        }
      }
    }

    if (map) {
      if (map && map.getSource('tileURL')) {
        // console.log('removing tileURL');
        map.removeLayer('tileURL');
        map.removeSource('tileURL');
      }
    }
  }, [dataLayer.api]);

  const polyMask = (mask, bounds) => {
    // const bboxPoly = bboxPolygon(bounds);
    // console.log({ mask, bounds });
    const maskPoly = turf.bboxPolygon(mask);
    const boundsPolyg = bounds;
    // console.log(maskPoly);
    // console.log(boundsPolyg);
    const dif = turf.difference(boundsPolyg, maskPoly);
    // console.log(dif, 'dif');
    return dif;
  };

  useEffect(() => {
    // console.log('tileURL', tileURL, tileUrlCounter);
    if (map && map.getSource('tileURL')) {
      // console.log('removing tileURL');
      map.removeLayer('tileURL');
      map.removeSource('tileURL');
    }

    if (tileURL && map && dataLayer.type === 'partialDR') {
      if (map.getLayer('tileURL')) {
        map.removeLayer('tileURL');
        map.removeSource('tileURL');
      }

      if (map.getLayer('tilemasklayer')) {
        map.removeLayer('tilemasklayer');
        map.removeSource('tilemask');
      }
      // console.log('adding tileURL, Partial', dataLayer, tileBounds);
      map.addSource('tileURL', {
        type: 'raster',
        tiles: [tileURL],
        tileSize: 256,
        // bounds: viewBounds,
      });

      map.addLayer({
        id: 'tileURL',
        source: 'tileURL',
        type: 'raster',
        minzoom: 0,
        maxzoom: MAXZOOM,
        layout: { visibility: 'visible' },
        // 'clip-bounding-box': viewBounds,
      });

      map.addSource('tilemask', {
        type: 'geojson',
        data: polyMask(viewBounds, tileBounds),
      });

      map.addLayer({
        id: 'tilemasklayer',
        type: 'fill',
        source: 'tilemask',
        paint: {
          'fill-color': 'rgba(46, 204, 113,0.2)',
        },
      });
    }

    if (tileURL && map && dataLayer.api === 'xyz') {
      // console.log('adding tileURL');
      map.addSource('tileURL', {
        type: 'raster',
        tiles: [tileURL],
        tileSize: 256,
        bounds: [
          map.getBounds()._sw.lng,
          map.getBounds()._sw.lat,
          map.getBounds()._ne.lng,
          map.getBounds()._ne.lat,
        ],
      });

      map.addLayer({
        id: 'tileURL',
        source: 'tileURL',
        type: 'raster',
        minzoom: 0,
        maxzoom: MAXZOOM,
        layout: { visibility: 'visible' },
      });
    }

    if (map && map.getSource('bbox-image')) {
      map.removeLayer('bbox-image');
      map.removeSource('bbox-image');
    }
  }, [tileURL, tileUrlCounter]);
  // Add a basemap switcher. Take it from api-docs
  useEffect(() => {
    const localMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [lng, lat],
      zoom,
      hash: true,
      maxZoom: MAXZOOM,
    });

    localMap.on('load', () => {
      localMap.on('zoomend', (e) => {
        setZoom(localMap.getZoom());
      });

      // map.showTileBoundaries = true;
      // map.showCollisionBoxes = true;

      onCreateListener = (e) => {
        // console.log('create', e.features[0]);

        if (mapDrawMode === 'draw_rectangle') {
          dispatch({
            type: setPolygon,
            payload: {
              polygon: e.features[0].geometry.coordinates,
            },
          });
          setShowClearButton(true);
        }
      };

      localMap.on('draw.create', onCreateListener);

      dispatch({
        type: setAPI,
        payload: {
          api: 'bbox',
          drawMode: 'draw_rectangle',
        },
      });
      // localMap.loadImage('./Group.png', (error, image) => {
      //   console.log('XXXXXXX');
      //   if (error) {
      //     console.log(error);
      //   } else {
      //     localMap.addImage('coverageMarker', image, { sdf: true });
      //   }
      // });
    });

    localMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    localMap.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        types: 'country,region,district,place,locality,postcode',
        collapsed: true,
        marker: {
          color: '#01B075',
        },
      })
    );

    setMap(localMap);
    mapRef.current = localMap;

    // Clean up on unmount
    return () => localMap.remove();
  }, []);

  useEffect(() => {
    const mapDrawModeLocal = dataLayer.drawMode;
    // console.log('mapdrawmode: ', mapDrawModeLocal);
    if (map) {
      // console.log('mapdrawMode: map');
      if (onCreateListener) {
        map.off('draw.create', onCreateListener);
      }
      onCreateListener = (e) => {
        // console.log('create', e.features[0]);
        // console.log(mapDrawModeLocal);

        if (mapDrawModeLocal === 'draw_rectangle') {
          dispatch({
            type: setPolygon,
            payload: {
              polygon: e.features[0].geometry.coordinates,
            },
          });
          setShowClearButton(true);
        }
      };

      map.on('draw.create', onCreateListener);

      // console.log('mapdrawMode: attaching Listener', onCreateListener);

      map.on('draw.delete', (e) => {
        console.log('del', e.features[0]);
      });
      map.on('draw.update', (e) => {
        console.log('update', e);
      });
    }
  }, [dataLayer.drawMode]);

  useEffect(() => {
    if (
      draw &&
      point &&
      point.length &&
      dataLayer.drawMode === 'draw_single_point'
    ) {
      const featureCollection = draw.getAll();
      featureCollection.features[0].geometry.coordinates = [
        parseFloat(point[0]),
        parseFloat(point[1]),
      ];

      draw.set(featureCollection);
    }
  }, [point]);

  // This is to set the draw rectangle on the map
  useEffect(() => {
    // console.log('polygon0000', polygon);

    if (
      draw &&
      polygon &&
      polygon.length &&
      dataLayer.drawMode === 'draw_rectangle'
    ) {
      const featureCollection = draw.getAll();
      // console.log('polygon0000', polygon);

      if (!featureCollection.features.length) {
        featureCollection.features = [
          {
            type: 'Feature',
            geometry: { coordinates: [], type: 'Polygon' },
            properties: {},
          },
        ];
      }

      featureCollection.features[0].geometry.coordinates = polygon;
      draw.set(featureCollection);
    }
  }, [polygon]);

  useEffect(() => {
    if (map && location) {
      // console.log('fly to location', location);
      map.flyTo({
        center: location.center,
        // eslint-disable-next-line no-nested-ternary
        zoom: location.zoom ? location.zoom : mode === 'draw_coverage' ? 7 : 14,
      });
    }
  }, [location]);

  const toggleBaseLayer = () => {
    if (basemap === 'satellite-streets-v11') {
      map.setStyle('mapbox://styles/mapbox/light-v11');
      setBasemap('light-v11');
    } else {
      map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      setBasemap('satellite-streets-v11');
    }
  };

  useEffect(() => {
    if (map) map.resize();
  }, [isRightDrawerOpen]);

  useEffect(() => {
    // Maybe once we need to do this without panning. i.e immediately after the mode is changed
    // console.log('dataLayer/mode changed', dataLayer);
    if (dataLayer.api === 'bbox' && map && dataLayer.type === 'partialDR') {
      const tilesArray = getTilesForCurrentBounds(map, tileBounds);
      const t = [
        tilesArray.map((tile) => tile[0]),
        tilesArray.map((tile) => tile[1]),
        tilesArray[0][2],
      ];

      dispatch({
        type: setTiles,
        payload: {
          tiles: t,
        },
      });

      map.on('moveend', (e) => {
        // console.log(e);
        if (dataLayer.api === 'bbox' && map && dataLayer.type === 'partialDR') {
          const tilesArrayCurrent = getTilesForCurrentBounds(map, tileBounds);

          const tilesCurrent = [
            tilesArrayCurrent.map((tile) => tile[0]),
            tilesArrayCurrent.map((tile) => tile[1]),
            tilesArrayCurrent[0][2],
          ];

          dispatch({
            type: setTiles,
            payload: {
              tiles: tilesCurrent,
            },
          });
        }
      });
    }
  }, [dataLayer]);

  useEffect(() => {
    if (map) {
      const geojsonSource = map.getSource('selected-feature-timeseries');
      if (map.getSource('selected-feature-timeseries') && !fieldId) {
        map.removeLayer('selected-feature-timeseries');
        map.removeSource('selected-feature-timeseries');
      }
    }
  }, [fieldId]);

  const removeMGRSExpectionLayer = () => {
    if (map && map.getSource('mgrsException-feature')) {
      map.removeLayer('mgrsException-layer');
      map.removeLayer('mgrsException-line');
      map.removeSource('mgrsException-feature');
    }
  };

  useEffect(() => {
    if (map && mgrsExceptionPolygon) {
      removeMGRSExpectionLayer();

      map.addSource('mgrsException-feature', {
        type: 'geojson',
        data: mgrsExceptionPolygon,
      });

      map.addLayer({
        id: 'mgrsException-layer',
        type: 'fill',
        source: 'mgrsException-feature',
        paint: {
          'fill-color': 'rgba(255, 255, 255, 0.2)',
        },
      });

      map.addLayer({
        id: 'mgrsException-line',
        type: 'line',
        source: 'mgrsException-feature',
        paint: {
          'line-width': 2,
          'line-color': '#fff',
        },
      });
    }
  }, [mgrsExceptionPolygon]);

  return (
    <div className="map-and-controls-container">
      <div className="map-container" ref={mapContainerRef} />
      <div className={classes.searchBarContainer}>
        <div
          className={`${classes.searchFormContainer} ${
            isSearchBarVisible ? classes.searchFormContainerOpen : ''
          }`}
        >
          {isSearchBarVisible && (
            <>
              {(searchValues.longitude || searchValues.latitude) && (
                <IconButton title="Coordinate Search Close">
                  <CloseIcon
                    style={{
                      fontSize: '25px',
                      marginTop: '6px',
                      marginLeft: '6px',
                      marginBottom: '6px',
                    }}
                    onClick={() => {
                      handleSearchClose();
                    }}
                  />
                </IconButton>
              )}

              <form className={classes.searchForm}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="latitude">Latitude</InputLabel>
                  <OutlinedInput
                    id="latitude"
                    name="latitude"
                    value={searchValues.latitude}
                    onChange={handleSearchChange}
                    label="Latitude"
                    required
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="longitude">Longitude</InputLabel>
                  <OutlinedInput
                    id="longitude"
                    name="longitude"
                    value={searchValues.longitude}
                    onChange={handleSearchChange}
                    label="Longitude"
                    required
                  />
                </FormControl>
              </form>
            </>
          )}
        </div>
        <IconButton
          onClick={() => {
            if (searchValues.longitude && searchValues.latitude) {
              handleSearchSubmit();
            }
            setIsSearchBarVisible(!isSearchBarVisible);
          }}
          style={{ width: '50px', height: '50px' }}
          title="Coordinate Search"
        >
          {isSearchBarVisible ? (
            <SearchBar
              classes={{ root: classes.iconRoot }}
              style={{ fontSize: '30px', marginTop: '10px' }}
            />
          ) : (
            <CompassIcon
              classes={{ root: classes.iconRoot }}
              style={{ fontSize: '30px' }}
            />
          )}
        </IconButton>
      </div>
      <div className={classes.globeIconContainer}>
        <Paper>
          <IconButton onClick={toggleBaseLayer} className={classes.globebutton}>
            <LineGlobeIcon classes={{ root: classes.iconRoot }} />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.checkboxContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={mgrsCheckbox}
              onChange={handleCheckboxChange}
              color="default"
            />
          }
          label="MGRS"
          labelPlacement="end"
        />
      </div>
      <div className={classes.clearButtonContainer}>
        {showClearButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.clearButton}
            onClick={() => {
              draw.deleteAll();
              dispatch({
                type: setDrawMode,
                payload: {
                  drawMode: 'draw_rectangle',
                },
              });
              removeMGRSExpectionLayer();
              dispatch({
                type: setMGRSTileExceptionPolygon,
                payload: {
                  mgrsExceptionPolygon: null,
                },
              });
              if (map.getSource('tileURL')) {
                map.removeLayer('tileURL');
                map.removeSource('tileURL');
              }
              if (map.getSource('tilemask')) {
                map.removeLayer('tilemasklayer');
                map.removeSource('tilemask');
              }
              if (map.getSource('bbox-area-mask')) {
                map.removeLayer('bbox-area-mask');
                map.removeSource('bbox-area-mask');
              }
              if (map.getSource('point')) {
                map.removeLayer('point');
                map.removeSource('point');
              }
              draw.changeMode('draw_rectangle');
            }}
          >
            Clear
          </Button>
        )}
      </div>

      <div className={classes.drawerToggleContainer}>
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButton}
        >
          {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <div
        className={
          isRightDrawerOpen
            ? classes.drawerToggleMobileOpen
            : classes.drawerToggleMobileClosed
        }
      >
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButtonMobile}
        >
          {isRightDrawerOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </div>
    </div>
  );
};

export default Map;
