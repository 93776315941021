import { get } from './API';

function getTotalCount(data) {
  return parseFloat(
    data.reduce((total, item) => total + item.count, 0).toFixed(2)
  );
}

function transformFieldData(data, productType) {
  const result = {
    productName: 'Delineated Field Boundaries',
    resolution:
      productType === 'highRes' ? 'High resolution ' : 'Low resolution ',
    id: productType === 'highRes' ? '2' : '3',
    requests: [],
    productType,
    totalUsage: 0,
    totalAreaConsumed: 0,
    totalCost: 'Not Available',
  };

  data.forEach((usage) => {
    const type = productType === 'highRes' ? 'hectare' : 'field';
    const areaConsumed =
      usage.usages.area.length > 0 ? getTotalCount(usage.usages.area) : 0;
    const uniqueValue =
      usage.usages.count.length > 0 ? getTotalCount(usage.usages.count) : 0;
    const replaysValue = getTotalCount(usage.usages.replays);

    const request = {
      tokenId: usage.apiKeyId,
      type,
      usage: [
        {
          type: 'unique',
          name: type,
          value: uniqueValue,
          areaConsumed: type === 'hectare' ? areaConsumed : 0,
        },
        {
          type: 'replays',
          name: type,
          value: replaysValue,
        },
      ],
      cost: 'Not Available',
      name: usage.apiKeyName,
      accessType: 'API',
    };

    result.requests.push(request);
    result.totalUsage += uniqueValue;
    result.totalAreaConsumed += areaConsumed;
  });

  return result;
}

export const getUserUsages = async (month) => {
  const response = await get('/usages', { month });
  const results = [];

  Object.keys(response.usages).forEach((orgId) => {
    const orgData = response.usages[orgId];

    if (orgData.highRes) {
      const highResResult = transformFieldData(orgData.highRes, 'highRes');
      results.push(highResResult);
    }

    if (orgData.lowRes) {
      const lowResResult = transformFieldData(orgData.lowRes, 'lowRes');
      results.push(lowResResult);
    }
  });

  return results;
};

export const transformPDRUsageData = (data) => {
  let incrementalId = 0;
  const transformedData = {
    productName: 'Deep Resolution Imagery',
    id: incrementalId,
    requests: [],
    totalUsage: 0,
    totalAreaConsumed: 0,
    totalCost: 0,
  };

  Object.keys(data).forEach((tokenId) => {
    const tokenData = data[tokenId];
    if (!transformedData.productName) {
      transformedData.productName = tokenData.product_name;
      transformedData.id = (incrementalId += 1).toString();
    }

    const request = {
      tokenId,
      type: tokenData.request_type.toLowerCase().includes('image')
        ? 'image'
        : tokenData.request_type,
      usage: [
        {
          name: 'image',
          type: 'Sq.km',
          value: parseInt(tokenData.images_count, 10),
          areaConsumed: tokenData.area_consumed,
        },
      ],
      cost: tokenData.total_cost,
      name: tokenData.email,
      accessType: tokenData.access_type,
    };

    transformedData.requests.push(request);
    transformedData.totalUsage += parseInt(tokenData.images_count, 10);
    transformedData.totalAreaConsumed += tokenData.area_consumed;
    transformedData.totalCost += tokenData.total_cost;
  });

  transformedData.totalAreaConsumed =
    transformedData.totalAreaConsumed.toFixed(2);
  transformedData.totalCost = transformedData.totalCost.toFixed(2);

  return transformedData;
};
