import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import LinearProgressWithLabel from './LinearProgressWithLabel';

const ProgressModal = ({ open, onClose, progress, message }) => {
  const [disableExit, setDisableExit] = useState(true);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={disableExit}
      aria-labelledby="progress-modal-title"
      aria-describedby="progress-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <LinearProgressWithLabel value={progress} />
        <Typography
          id="progress-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          {message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ProgressModal;
