/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

import { get, post } from '../API';

import { transformPDRUsageData } from '../usages';

export const listSubscriptions = async ({ limit = 100, page = 0 }) => {
  const response = await get(
    '/subscriptions',
    {
      limit,
      page,
    },
    {},
    'partialDR'
  );
  // console.log('response', response);
  return response;
};

export const getSentinelCover = async ({ start_date, end_date, bbox }) => {
  const params = {
    start_date,
    end_date,
    bbox,
  };

  const response = await post('/sentinel-cover', params, {}, 'partialDR');

  return response;
};

export const getCloudCover = async ({ tile, bbox }) => {
  const payload = {
    tile,
    bbox,
  };

  const response = await post('/cloud-cover', payload, {}, 'partialDR');

  return response;
};

export const createSubscription = async ({
  name,
  startTime,
  endTime,
  bbox,
  problematicAreaPercentage,
  imageCount,
  aoi,
}) => {
  const payload = {
    name,
    startTime,
    endTime,
    bbox,
    problematicAreaPercentage,
    imageCount,
    aoi,
  };

  const response = await post('/subscriptions', payload, {}, 'partialDR');

  return response;
};

export const createSubscriptionImagery = async ({
  subscriptionId,
  date,
  cloudCover,
  dataCover,
  dataSource,
  props,
}) => {
  const payload = {
    date,
    cloudCover: cloudCover || 0,
    dataCover: dataCover || 0,
    dataSource,
    props,
  };

  const response = await post(
    `/subscriptions/${subscriptionId}/imageries`,
    payload,
    {},
    'partialDR'
  );

  return response;
};

export const getSubscriptionImageries = async ({
  subscriptionId,
  page = 0,
  limit = 10,
}) => {
  const response = await get(
    `/subscriptions/${subscriptionId}/imageries`,
    {
      page,
      limit,
    },
    {},
    'partialDR'
  );

  return response;
};

export const getActiveTokenAndCredits = async () => {
  const response = await get(`/user/credits`, {}, {}, 'partialDR');

  return response;
};

export const getTokenUsageConsumptionList = async (monthQuery) => {
  try {
    const response = await get(
      `/tokens/usage?month=${monthQuery}`,
      {},
      {},
      'partialDR'
    );
    const data = transformPDRUsageData(response);
    return data;
  } catch (error) {
    // Handle error, possibly returning a default value or rethrowing the error
    console.error('Error fetching token and credits: ', error);
    throw error;
  }
};
