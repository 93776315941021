import { API } from 'aws-amplify';

const APINAME = 'public';

export const post = async (path, payload = {}, headers = {}, api = APINAME) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.post(api, path, {
      headers: payloadHeaders,
      body: payload,
    });
  } catch (err) {
    const {
      status,
      data: { message, type },
    } = err.response;
    // console.log('Error: ', err.response);
    // console.log(message, type, status);

    return Promise.reject(err);
  }
};

export const get = async (
  path,
  queryStringParameters = {},
  headers = {},
  api = APINAME
) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.get(api, path, {
      headers: payloadHeaders,
      queryStringParameters,
    });
  } catch (err) {
    const {
      status,
      data: { message, type },
    } = err.response;
    // console.log('Error: ', err.response);
    // console.log(message, type, status);

    return Promise.reject(err);
  }
};

export const destroy = async (path, headers = {}, api = APINAME) => {};

export const put = async (
  path,
  payload = {},
  headers = {},
  api = APINAME
) => {};
