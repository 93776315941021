import {
  CLEAR_USER,
  CLEAR_ERRORS,
  SIGN_UP_INIT,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_USER_INIT,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_FAIL,
  RESEND_CONFIRMATION_CODE_INIT,
  RESEND_CONFIRMATION_CODE_SUCCESS,
  RESEND_CONFIRMATION_CODE_FAIL,
  SIGN_IN_INIT,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  LOAD_CURRENT_USER_INIT,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_FAILURE,
  SIGN_OUT,
} from './actions';

const authReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_USER: {
      return {
        ...state,
        user: null,

        signUpError: null,
        confirmUserError: null,
        resendConfirmationCodeError: null,
        signInError: null,
        forgotPasswordError: null,
        resetPasswordError: null,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,

        signUpError: null,
        confirmUserError: null,
        resendConfirmationCodeError: null,
        signInError: null,
        forgotPasswordError: null,
        resetPasswordError: null,
      };
    }
    case SIGN_UP_INIT: {
      return {
        ...state,
        isLoading: true,
        signUpError: null,
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        signUpError: null,
        user: action.payload.user,
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        signUpError: action.payload.errorMessage,
      };
    }
    case CONFIRM_USER_INIT: {
      return {
        ...state,
        isLoading: true,
        confirmUserError: null,
        resendConfirmationCodeError: null,
      };
    }
    case CONFIRM_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        confirmUserError: null,
        resendConfirmationCodeError: null,
      };
    }
    case CONFIRM_USER_FAIL: {
      return {
        ...state,
        isLoading: false,
        confirmUserError: action.payload.errorMessage,
        resendConfirmationCodeError: null,
      };
    }
    case RESEND_CONFIRMATION_CODE_INIT: {
      return {
        ...state,
        isLoading: true,
        confirmUserError: null,
        resendConfirmationCodeError: null,
      };
    }
    case RESEND_CONFIRMATION_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        confirmUserError: null,
        resendConfirmationCodeError: null,
      };
    }
    case RESEND_CONFIRMATION_CODE_FAIL: {
      return {
        ...state,
        isLoading: false,
        confirmUserError: null,
        resendConfirmationCodeError: action.payload.errorMessage,
      };
    }
    case SIGN_IN_INIT: {
      return {
        ...state,
        isLoading: true,
        signInError: null,
      };
    }
    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        signInError: null,
        user: action.payload.user,
      };
    }
    case SIGN_IN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        signInError: action.payload.errorMessage,
      };
    }
    case LOAD_CURRENT_USER_INIT: {
      return {
        ...state,
        isValidatingSession: true,
        signInError: null,
      };
    }
    case LOAD_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        isValidatingSession: false,
        signInError: null,
        user: action.payload.user,
      };
    }
    case LOAD_CURRENT_USER_FAILURE: {
      return {
        ...state,
        isValidatingSession: false,
        signInError: action.payload.errorMessage || null,
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        user: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default authReducer;
