import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { ArrowDownIcon } from 'utils/icons';
import truncateToken from 'utils/utils';
import { getUserUsages } from '../../api/usages';
import { getTokenUsageConsumptionList } from '../../api/partialdr/subscriptions';

import styles from './main-dashboard.module.css';

const useStyles = makeStyles({
  tokenDropdown: {
    padding: '0px !important',
    borderRadius: '4px',
    color: '#01B075',
    'font-size': '16px',
    'font-weight': 600,
  },
  tokenText: {
    color: '#1D2023',
    fontWeight: 600,
    fontSize: '16px',
  },
});

const MainDashboard = () => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState('all');
  const [productUsageList, setProductUsageList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [tokens, setTokens] = useState([{ label: 'All Tokens', value: 'all' }]);

  const fetchData = async (newDate) => {
    setIsLoading(true);
    try {
      if (!newDate) {
        return { tokens: [] };
      }
      const newDateObj = new Date(newDate);
      const year = newDateObj.getFullYear();
      const month = String(newDateObj.getMonth() + 1).padStart(2, '0');
      const monthQuery = `${year}-${month}`;

      const [tokenConsumption, usages] = await Promise.all([
        getTokenUsageConsumptionList(monthQuery),
        getUserUsages(monthQuery),
      ]);

      const allTokensUsageConsumption = [tokenConsumption, ...usages];
      setProductUsageList(allTokensUsageConsumption);

      const tokensFromData = allTokensUsageConsumption.flatMap((product) =>
        product.requests.map((token) => ({
          label: token.name,
          value: token.tokenId,
        }))
      );
      setTokens([{ label: 'All Tokens', value: 'all' }, ...tokensFromData]);

      return allTokensUsageConsumption;
    } catch (error) {
      console.error('Failed to fetch token and credits: ', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const filteredProductUsageList = productUsageList
    .filter((product) => product && product.requests.length)
    .map((product) => ({
      ...product,
      requests: product.requests.filter(
        (request) =>
          selectedToken === 'all' || request.tokenId === selectedToken
      ),
      expanded: expandedRows.includes(product.id),
    }));

  useEffect(() => {
    fetchData(date);
  }, []);

  const handleTokenChange = (event) => {
    const selectToken = event.target.value;
    setSelectedToken(selectToken);

    if (selectToken === 'all') {
      setExpandedRows([]);
    } else {
      const expandedProductIds = productUsageList
        .filter((product) =>
          product.requests.some((request) => request.tokenId === selectToken)
        )
        .map((product) => product.id);
      setExpandedRows(expandedProductIds);
    }
  };

  const toggleRowExpansion = (productId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(productId)
        ? prevExpandedRows.filter((id) => id !== productId)
        : [...prevExpandedRows, productId]
    );
  };

  return (
    <div>
      <Typography variant="h5" fontWeight={700}>
        Main Dashboard
      </Typography>
      <div className={styles.tableOptions}>
        <div className={styles.infoPill}>
          Table view :{' '}
          <Typography
            className={styles.headerText}
            sx={{ fontSize: '16px', fontWeight: 600 }}
            component="span"
          >
            Monthly Usage
          </Typography>
        </div>
        <div className={styles.datePickerContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              openTo="month"
              minDate={new Date('2021-08-01')}
              maxDate={new Date()}
              value={date}
              inputProps={{ readOnly: true }}
              InputAdornmentProps={{
                position: 'start',
              }}
              onClose={() => {
                setIsOpen(false);
              }}
              onAccept={(newDate) => {
                setIsOpen(false);
              }}
              onChange={(newDate) => {
                // eslint-disable-next-line no-restricted-globals
                if (newDate && newDate instanceof Date && !isNaN(newDate)) {
                  setDate(newDate);
                  fetchData(newDate);
                }
              }}
              open={isOpen}
              classes={{ root: classes.select }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className={styles.customBorderRadius}
                  classes={{
                    root: styles.select,
                    input: styles.calendarInput,
                  }}
                  helperText={null}
                  InputProps={{
                    style: {
                      fontSize: '16px',
                      fontWeight: 600,
                      borderRadius: '4px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: '16px',
                      fontWeight: 600,
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" mr={1} className={classes.tokenText}>
            Token:
          </Typography>
          <Select
            value={selectedToken}
            onChange={handleTokenChange}
            classes={{ root: classes.tokenDropdown }}
          >
            {tokens.map((token) => (
              <MenuItem key={token.value} value={token.value}>
                {token.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </div>

      {!isLoading ? (
        <div className={styles.tableContainer}>
          <table className={styles.dashboardTable}>
            <thead className={styles.tableTitle}>
              <tr>
                <th>Product</th>
                <th>Access Type</th>
                <th>Request Type</th>
                <th>Usage</th>
                <th>Area Consumed</th>
                <th>Cost</th>
                <th aria-label="Empty cells" />
              </tr>
            </thead>
            <tbody className={styles.tableItems}>
              {filteredProductUsageList.map((product) => (
                <React.Fragment key={product.id}>
                  <tr
                    onClick={() => toggleRowExpansion(product.id)}
                    className={styles.expandableRow}
                  >
                    <td>
                      <div className={styles.productName}>
                        {product.productName}
                      </div>
                      <div className={styles.faded}>{product.resolution}</div>
                    </td>
                    <td aria-label="Empty cells" />
                    <td aria-label="Empty cells" />
                    <td>
                      <div className={styles.requestTypeContent}>
                        <div className={styles.bold}>{product.totalUsage}</div>
                        <div className={styles.faded}>
                          {product.productName === 'Deep Resolution Imagery'
                            ? 'Images'
                            : 'Fields'}
                        </div>
                      </div>
                    </td>
                    <td>
                      {product.totalAreaConsumed > 0 && (
                        <div className={styles.requestTypeContent}>
                          <div className={styles.bold}>
                            {product.totalAreaConsumed}
                          </div>
                          <div className={styles.faded}>
                            {product.productType === 'highRes'
                              ? 'hectares'
                              : 'sq.km'}{' '}
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className={styles.requestTypeContent}>
                        <div className={styles.bold}>
                          {product.totalCost !== 'Not Available' ? '€' : ''}{' '}
                          {product.totalCost}
                        </div>
                        <div className={styles.faded}>Totals</div>
                      </div>
                    </td>
                    <td>
                      {product.expanded ? (
                        <span className={styles.expandIcon}>-</span>
                      ) : (
                        <span className={styles.expandIcon}>+</span>
                      )}
                    </td>
                  </tr>
                  {product.expanded &&
                    product.requests.map((request) => (
                      <React.Fragment key={request.tokenId}>
                        <tr>
                          <td>
                            <div className={styles.requestTypeContent}>
                              <div className={styles.bold}>
                                <div className={styles.iconWrapper}>
                                  <ArrowDownIcon
                                    className={styles.icon}
                                    stroke="#949EA7"
                                  />
                                  <span>{request.name}</span>
                                </div>
                              </div>
                              <div
                                className={styles.faded}
                              >{`**** ${truncateToken(request.tokenId)}`}</div>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                backgroundColor:
                                  request.accessType === 'API'
                                    ? '#ede8fc'
                                    : '#fdf3e8',
                                color:
                                  request.accessType === 'API'
                                    ? '#7F5CEB'
                                    : '#EA891D',
                              }}
                              className={styles.statusCell}
                            >
                              {request.accessType}
                            </div>
                          </td>
                          <td>
                            {request.usage.map((usageItem, index) => (
                              <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className={styles.requestTypeContent}
                              >
                                <div className={styles.bold}>
                                  Per {usageItem.name ?? 'field'}
                                </div>
                                <div className={styles.faded}>
                                  {usageItem.type}
                                </div>
                              </div>
                            ))}
                          </td>
                          <td>
                            {request.usage.map((usageItem, index) => (
                              <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className={styles.requestTypeContent}
                              >
                                <div className={styles.bold}>
                                  {usageItem.value}
                                </div>
                                <div className={styles.faded}>
                                  {product.productName ===
                                  'Deep Resolution Imagery'
                                    ? 'Images'
                                    : 'Fields'}
                                </div>
                              </div>
                            ))}
                          </td>
                          <td>
                            {request.usage.map((usageItem, index) =>
                              usageItem.areaConsumed ? (
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className={styles.requestTypeContent}
                                >
                                  <div className={styles.bold}>
                                    {usageItem.areaConsumed}
                                  </div>
                                  <div className={styles.faded}>
                                    {usageItem.name === 'hectare'
                                      ? 'hectares'
                                      : 'sq.km'}{' '}
                                  </div>
                                </div>
                              ) : null
                            )}
                          </td>
                          <td>
                            <div className={styles.requestTypeContent}>
                              <div className={styles.bold}>
                                {request.cost !== 'Not Available' ? '€' : ''}{' '}
                                {request.cost}
                              </div>
                              <div className={styles.faded}>credits</div>
                            </div>
                          </td>
                          <td aria-label="Empty cell" />
                        </tr>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className={styles.faded}>
            Note: High and Low Resolution Field Boundary data is updated every
            day at midnight.
          </div>
        </div>
      ) : (
        <div className={styles.usageCircularProgress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export default MainDashboard;
