import React, { createContext, useMemo, useReducer } from 'react';

import {
  setMode,
  setPoint,
  setPolygon,
  setFeature,
  setDrawMode,
  setTile,
  setFieldId,
  setLocation,
  setDataLayer,
  setToken,
  setTiles,
  setAPI,
  setTileURL,
  setDRResult,
  setMGRSTileExceptionPolygon,
} from 'contexts/Map/action';

const initialState = {
  mode: '',
  point: undefined,
  polygon: undefined,
  // dataLayer: 'partialDR',
  token: 'a0731a8c-5259-4c68-af3a-7ad4f6d53faa',
  tileUrlCounter: 0,
  dataLayer: {
    type: 'partialDR',
    api: 'bbox',
    drawMode: 'draw_rectangle',
  },
  drResult: undefined,
  renderOption: 'rgbn_rgb',
};

const store = createContext(initialState);
const { Provider } = store;

// State needs to be structured in a better way:

// General - location, dataLayer
// DR, Delineation (comes under datalayer)
// mode/api - location, bbox, coverage, fieldId. For DR Tile url, bbox, coverage
// Draw modes.
// Map payloads- features, image (in case of DR)
// Maybe Map related payload should go into the dataLayer.
// Draw will have common behavior but dispatch might differ based on the dataLayer/mode

const stateReducer = (state, action) => {
  // console.log(action, 'actions');
  switch (action.type) {
    case setMode:
      return {
        token: state.token,
        dataLayer: state.dataLayer,
        mode: action.payload.mode,
        drawMode: action.payload.drawMode,
      };
    case setPoint:
      return {
        ...state,
        point: action.payload.point,
        feature: undefined,
      };
    case setPolygon:
      return {
        ...state,
        polygon: action.payload.polygon,
        feature: undefined,
      };
    case setFeature:
      return {
        ...state,
        feature: action.payload.feature,
      };
    case setTile:
      return {
        ...state,
        tile: action.payload.tile,
      };
    case setFieldId:
      return {
        ...state,
        fieldId: action.payload.fieldId,
      };
    case setLocation:
      return {
        ...state,
        location: action.payload.location,
      };
    case setDataLayer:
      return {
        ...state,
        feature: undefined,
        dataLayer: action.payload.dataLayer,
        location: action.payload.location
          ? action.payload.location
          : state.location,
      };

    case setAPI:
      return {
        // ...state,
        token: state.token,
        dataLayer: {
          ...state.dataLayer,
          api: action.payload.api,
          drawMode: action.payload.drawMode ? action.payload.drawMode : '',
        },
      };
    case setToken:
      return {
        ...state,
        token: action.payload.token,
      };
    case setTiles:
      return {
        ...state,
        tiles: action.payload.tiles,
      };
    case setTileURL:
      return {
        ...state,
        tileUrlCounter: Math.random(),
        tileURL: action.payload.tileURL,
        tileBounds: action.payload.tileBounds,
        viewBounds: action.payload.viewBounds,
        tileId: action.payload.tileId,
      };
    case setDrawMode:
      return {
        ...state,
        dataLayer: {
          ...state.dataLayer,
          drawMode: action.payload.drawMode,
        },
      };
    case setDRResult:
      return {
        ...state,
        drResult: action.payload.drResult,
        renderOption: action.payload.renderOption,
      };
    case setMGRSTileExceptionPolygon:
      return {
        ...state,
        mgrsExceptionPolygon: action.payload.polygon,
      };
    default:
      throw new Error();
  }
};

const StateProvider = (props) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  // const value = useMemo(() => [state, dispatch], [state]);

  return <Provider value={{ state, dispatch }} {...props} />;
};

export { store, StateProvider };
