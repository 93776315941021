import React, { useContext, useEffect, useState } from 'react';
import CompareMap from 'components/QuickDRMapView/QuickDRCompareView';
import NavBar from 'components/NavBar';
import { useAuthContextState, useAuthContext } from 'contexts/Auth';
import { BusinessIcon, UserCircleIcon, LogoutIcon } from 'utils/icons';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { signOutUser } from 'contexts/Auth/actions';
import signOut from 'helpers/signOut';
import SentinelProcessedImageriesTable from 'components/QuickDRMapView/SentinelProcessedImageriesDrawer';
import { store } from 'contexts/Map/store';
import { getSubscriptionImageries } from 'api/partialdr/subscriptions';
import { useQuery } from '@tanstack/react-query';
import { BackArrowIcon } from 'icons';
import styles from './mapview.module.css';

const ViewSubscriptionsImageries = () => {
  const urlLocation = useLocation();
  const { subscriptionId } = useParams();

  const [bbox, setBbox] = useState(null);
  const [name, setName] = useState(null);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  // const location = useLocation();

  // const {
  //   state: { mode, drawMode: mapDrawMode, dataLayer, location },
  //   dispatch,
  // } = useContext(store);

  useEffect(() => {
    const queryParams = new URLSearchParams(urlLocation.search);
    setName(queryParams.get('name'));

    if (queryParams.get('bbox')) {
      const b = queryParams
        .get('bbox')
        .split(',')
        .map((x) => parseFloat(x));
      setBbox(b);
      // console.log('bbox', bbox, b);
    }
  }, [urlLocation]);

  // Extract zoom from url parameters when the window loads
  const initialZoom = window.location.hash
    ? window.location.hash.substr(1).split('/')[0]
    : 13;
  const [zoom, setZoom] = useState(initialZoom);
  const [toggleLeftDrawer, setToggleLeftDrawer] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };
  const { isLoading, error, data } = useQuery(
    ['subscriptionImageries', subscriptionId, pageIndex, pageSize],
    async () => {
      const ImageryList = await getSubscriptionImageries({
        subscriptionId,
        page: pageIndex,
        limit: pageSize,
      });

      if (Array.isArray(ImageryList)) {
        return ImageryList;
      }

      return [];
    },
    {
      enabled: !!subscriptionId,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
    }
  );

  return (
    <div>
      <NavBar Title={name} />
      <div className={styles.dashboardRoot}>
        <div className={styles.rightPanel}>
          <Link
            className={styles.backToProjectsContainer}
            to="/quick-dr/subscriptions"
          >
            <BackArrowIcon className="icon-root" />
            <div>Back to Orders</div>
          </Link>

          <div className={styles.mainDashboard}>
            <div className="map-and-drawer">
              {bbox ? (
                <>
                  <CompareMap bbox={bbox} />
                  <SentinelProcessedImageriesTable
                    open
                    onClose={() => {}}
                    data={data}
                    error={error}
                    isLoading={isLoading}
                    setQueryPageIndex={setPageIndex}
                    setQueryPageSize={setPageSize}
                    queryPageIndex={pageIndex}
                    queryPageSize={pageSize}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSubscriptionsImageries;
