import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import SignUpContainer from 'components/SignUpContainer';
import styles from './authentication.module.css';

const ForgotPassword = ({
  resetRequested = false,
  setResetRequested = () => {},
}) => (
  <SignUpContainer>
    <h2>Forgot Your Password?</h2>

    {!resetRequested ? (
      <>
        <Typography color="text.secondary" marginBottom={2}>
          Enter the email address you use to sign in to Digifarm.
        </Typography>

        <TextField
          label="Email"
          variant="filled"
          type="email"
          fullWidth
          className={styles.signUpInput}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => {
            setResetRequested(true);
          }}
        >
          Reset Password
        </Button>
        <Typography marginTop={10} color="text.grey">
          <Link to="/sign-up">Go back to Sign In</Link>
        </Typography>
      </>
    ) : (
      <>
        <Typography marginBottom={8} color="text.grey">
          If there is an account associated with this email address you will
          receive a password reset email.
        </Typography>
        <Button variant="contained" color="primary" size="large" fullWidth>
          Go back to Sign In
        </Button>
        <img
          src="/post1.png"
          alt="Post card carrying pigeon"
          className={styles.pigeonImg}
        />
      </>
    )}
  </SignUpContainer>
);

ForgotPassword.propTypes = {
  resetRequested: PropTypes.bool,
  setResetRequested: PropTypes.func,
};

export default ForgotPassword;
