import React, { useState, useContext } from 'react';
import Map from 'components/QuickDRMapView';

import { makeStyles } from '@mui/styles';

import { BackArrowIcon } from 'icons';

import { store } from 'contexts/Map/store';

import NavBar from 'components/NavBar';
import { useAuthContextState, useAuthContext } from 'contexts/Auth';

import { Link, useLocation, useHistory } from 'react-router-dom';

import RightDrawer from 'components/QuickDRMapView/RightDrawer';
import {
  DrawProvider,
  MapProvider,
} from '../../components/QuickDRMapView/index';

import styles from './mapview.module.css';

const QuickDRMapView = () => {
  // const classes = useStyles();

  const authState = useAuthContextState();

  const [_authState, authDispatch] = useAuthContext();
  // const location = useLocation();
  const history = useHistory();

  const [region, setRegion] = useState('norway');

  const {
    state: { mode, drawMode: mapDrawMode, dataLayer, location },
    dispatch,
  } = useContext(store);

  // Extract zoom from url parameters when the window loads
  const initialZoom = window.location.hash
    ? window.location.hash.substr(1).split('/')[0]
    : 13;
  const [zoom, setZoom] = useState(initialZoom);
  const [toggleLeftDrawer, setToggleLeftDrawer] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  // console.log('authState', authState);
  return (
    <div>
      <NavBar Title="Create Partial DR" />
      <MapProvider>
        <DrawProvider>
          <div className={styles.dashboardRoot}>
            <div className={styles.rightPanel}>
              <Link
                className={styles.backToProjectsContainer}
                to="/quick-dr/subscriptions"
              >
                <BackArrowIcon className="icon-root" />
                <div>Back to Orders</div>
              </Link>
              <div className={styles.mainDashboard}>
                <div className="map-and-drawer">
                  <Map
                    layer={dataLayer.type}
                    zoom={zoom}
                    setZoom={setZoom}
                    toggleRightDrawer={toggleRightDrawer}
                    isRightDrawerOpen={isRightDrawerOpen}
                  />

                  <RightDrawer open onClose={() => {}} />
                </div>
              </div>
            </div>
          </div>
        </DrawProvider>
      </MapProvider>
    </div>
  );
};

export default QuickDRMapView;
