import { Drawer, Select, MenuItem, Box, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { store } from 'contexts/Map/store';
import { setAPI } from 'contexts/Map/action';

import { makeStyles } from '@mui/styles';
import SentinelProcessedImageriesView from './SentinelProcessedImageriesView';

const useStyles = makeStyles({
  drawer: {
    width: 560,
    '@media (max-width: 600px)': {
      width: 'unset',
      maxHeight: 300,
      overflowY: 'scroll',
    },
  },
  root: {
    width: 560,
    '@media (max-width: 600px)': {
      width: 'unset',
      maxHeight: 300,
    },
    backgroundColor: '#F8F9FA',
    fontSize: 14,
    '& h3': {
      fontSize: 20,
      lineHeight: 1.2,
      margin: 0,
    },
  },
  info: {
    padding: '24px 20px',
    boxSizing: 'border-box',
    color: '#949EA7',
    backgroundColor: '#fff',
    marginBottom: 8,
    '& h3': {
      lineHeight: '24px',
      marginBottom: 8,
      color: '#1D2023',
    },
    '& h4': {
      color: '#1D2023',
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
      margin: 0,
    },
    '& h6': {
      color: '#1D2023',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      margin: 0,
    },
  },

  flex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
  },
  inputRoot: {
    borderRadius: 4,
  },
  dropdownContainer: {
    backgroundColor: '#fff',
  },
  select: {
    width: '100%',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 700,
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1em',
  },
  selectInputRoot: {
    '&::before': {
      borderBottomColor: '#EEEFF1',
    },
  },
});

const RightDrawer = ({
  open,
  onClose,
  isLoading,
  error,
  data,
  queryPageIndex,
  setQueryPageIndex,
  queryPageSize,
  setQueryPageSize,
}) => {
  const classes = useStyles();

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer
      anchor={windowSize < 600 ? 'bottom' : 'right'}
      open={open}
      onClose={onClose}
      ModalProps={{ BackdropProps: { invisible: true } }}
      variant="persistent"
      className={open ? 'right-side-drawer open' : 'right-side-drawer'}
      PaperProps={{
        style: {
          top: '64px',
        },
      }}
    >
      <div className={classes.root}>
        <SentinelProcessedImageriesView
          sentinelImagery={data}
          queryPageIndex={queryPageIndex}
          setQueryPageIndex={setQueryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageSize={setQueryPageSize}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </Drawer>
  );
};

export default RightDrawer;
