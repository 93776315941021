import { Auth } from 'aws-amplify';

/**
 * Obtains current cognito session
 */
export const getCurrentSession = async () => Auth.currentSession();

/**
 * Register the user with email and password
 *
 * @param {string} name Name
 * @param {string} email Email Id
 * @param {string} password Password
 */
export const registerWithEmailPasswordAsync = async (name, email, password) => {
  const formattedEmail = email.toLowerCase();

  try {
    const res = await Auth.signUp({
      username: formattedEmail,
      password,
      attributes: {
        email: formattedEmail,
        name,
      },
    });
    return res;
  } catch (err) {
    if (err.code) {
      return {
        error: {
          message: err.message,
        },
      };
    }

    return Promise.reject(err);
  }
};

/**
 * Resend confirmation code
 *
 * @param {string} email Email Id
 */
export const resendConfirmationAsync = async (email) => {
  const formattedEmail = email.toLowerCase();
  try {
    return Auth.resendSignUp(formattedEmail);
  } catch (err) {
    if (err.code) {
      return {
        error: {
          message: err.message,
        },
      };
    }

    return Promise.reject(err);
  }
};

/**
 * Confirm user email
 *
 * @param {string} email Email Id
 * @param {string} code Confirmation code
 */
export const confirmUserAsync = async (email, code) => {
  const formattedEmail = email.toLowerCase();
  try {
    const res = await Auth.confirmSignUp(formattedEmail, code, {
      forceAliasCreation: false,
    });

    return res === 'SUCCESS';
  } catch (err) {
    return {
      error: {
        message: err.message,
      },
    };
  }
};

/**
 * Sign in user with email and password
 *
 * @param {string} email Email Id
 * @param {string} password Password
 */
export const signInWithEmailPasswordAsync = async (email, password) => {
  const formattedEmail = email.toLowerCase();

  try {
    const res = await Auth.signIn(formattedEmail, password);

    return {
      user: res.attributes,
    };
  } catch (err) {
    if (
      err.code === 'NotAuthorizedException' ||
      err.code === 'UserNotFoundException'
    ) {
      return {
        error: {
          code: 'EmailOrPasswordNotFound',
        },
      };
    }

    if (
      err.code === 'UserNotConfirmedException' ||
      err.code === 'PasswordResetRequiredException'
    ) {
      return {
        error: {
          code: err.code,
        },
      };
    }

    return Promise.reject(err);
  }
};

/**
 * Load user from local storage
 */
export const loadCurrentUserAsync = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user && user.username) {
      return user;
    }

    return false;
  } catch (err) {
    return false;
  }
};

/**
 * Sign out user
 */
export const signOutAsync = async () => Auth.signOut();
