import { useState } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { InfoIcon } from 'icons';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  borderRadius: 1,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 55,
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,

    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },

  '& .MuiSlider-rail': {
    color: '#EEEFF1',
    opacity: 1,
  },
}));

const CustomIconButton = styled(IconButton)({
  padding: 0,
  margin: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 225,
  },
}));

function SliderThumbComponent(props) {
  const { children, style, ...other } = props;
  return (
    <SliderThumb style={style} {...other}>
      {children}
      <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'text.primary' }}>
        &le;{style.left}
      </Typography>
    </SliderThumb>
  );
}

const CloudCoverageSlider = ({
  cloudCoveragePercentage,
  setCloudCoveragePercentage,
}) => {
  const handleSliderChange = (event, newValue) => {
    setCloudCoveragePercentage(newValue);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" my={2}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 700,
          color: 'text.primary',
          overflow: 'visible',
          marginRight: 0.5,
        }}
        noWrap
        inline="true"
      >
        Cloud Coverage:
      </Typography>
      <CustomToolTip
        title="This value includes snow, defective pixels, low and high probability clouds, cloud shadows, data cover, etc."
        placement="top"
        arrow
      >
        <CustomIconButton>
          <InfoIcon className="icon-root" />
        </CustomIconButton>
      </CustomToolTip>
      <CustomSlider
        value={
          typeof cloudCoveragePercentage === 'number'
            ? cloudCoveragePercentage
            : 0
        }
        onChange={handleSliderChange}
        slots={{ thumb: SliderThumbComponent }}
        aria-label="Cloud Coverage"
        color="secondary"
        sx={{
          marginLeft: 4,
          marginRight: 4,
        }}
      />
    </Stack>
  );
};

export default CloudCoverageSlider;
