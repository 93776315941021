import { Button, Select, MenuItem, TextField } from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { store } from 'contexts/Map/store';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { setDRResult } from 'contexts/Map/action';
// import { MuiPickersUtilsProvider } from '@mui/pickers';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import copyToClipboard from 'helpers/copyToClipboard';
import * as Bluebird from 'bluebird';
// import { LocalizationProvider, DatePicker } from '@mui/lab';
import ProgressModal from 'components/ProgressModal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Toast from 'components/Toast';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// import { LocalizationProvider, DatePicker } from '@mui/lab/';
// import DateFnsUtils from '@date-io/date-fns';
import {
  getSentinelCover,
  createSubscription as createSubscriptionAPI,
  createSubscriptionImagery as createSubscriptionImageryAPI,
} from 'api/partialdr/subscriptions';
import * as turf from '@turf/turf';
import InputField from './InputField';
// import Disclaimer from './Disclaimer';
import SentinelProcessedImageriesTable from './SentinelProcessedImageriesTable';

// import enGB from 'date-fns/locale/en-GB';

const useStyles = makeStyles({
  info: {
    padding: '24px 20px',
    boxSizing: 'border-box',
    color: '#949EA7',
    backgroundColor: '#fff',
    marginBottom: 8,
    '& h3': {
      lineHeight: '24px',
      marginBottom: 8,
      color: '#1D2023',
    },
    '& h4': {
      color: '#1D2023',
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
      margin: 0,
    },
    '& h6': {
      color: '#1D2023',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: 'none',
  },
  mt8: {
    marginTop: 8,
  },
  flex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
    wordWrap: 'anywhere',
  },
  rightContainer: {
    flex: 1,
    wordWrap: 'anywhere',
    color: '#1D2023',
    '& h6': {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: 'flex',
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: '100%',
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: '20px',
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    textTransform: 'capitalize',
  },
  url: {
    color: '#EA891D',
  },
  copyButton: {
    marginRight: 4,
    textTransform: 'capitalize',
    borderRadius: 4,
    marginTop: 8,
    '@media (max-width: 600px)': {
      position: 'static',
      marginBottom: 8,
    },
  },
  textFields: {
    display: 'flex',
    marginTop: 12,
  },

  parameters: {
    marginTop: '24px !important',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(68px, 1fr))',
    columnGap: '16px',
    rowGap: '16px',
  },
  drImageThumbnail: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #EEEFF1',
  },
  rasterUrlContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: 16,
  },
  rasterUrl: {
    color: '#1D2023',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'anywhere',
    marginRight: 8,
  },
  rasterUrlLabel: {
    fontSize: 12,
    marginBottom: 8,
  },
});

// const baseUrl = `${domain}/${api_version}`;
// To do: Needs to be changed when promoting to production

const PartialDRBboxDrawer = ({
  sentinelImagery,
  setQueryPageIndex,
  setQueryPageSize,
  queryPageIndex,
  queryPageSize,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const sentinelViewRef = useRef(null);
  const history = useHistory();
  const { dispatch } = useContext(store);

  const [requestUrl, setRequestUrl] = useState('');
  const [imageBlobUrl, setImageBlobUrl] = useState('');

  const renderTCI = (row, option) => {
    dispatch({
      type: setDRResult,
      payload: {
        drResult: JSON.parse(JSON.stringify(row)),
        renderOption: option,
      },
    });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.info}>
          <div className={classes.imageGrid}>
            {requestUrl && (
              <div>
                <img
                  className={classes.drImageThumbnail}
                  src={imageBlobUrl}
                  alt="Available Sentinel Imageries"
                />
              </div>
            )}
          </div>
          <div>
            <SentinelProcessedImageriesTable
              sentinelImagery={sentinelImagery}
              renderTCI={renderTCI}
              queryPageIndex={queryPageIndex}
              setQueryPageIndex={setQueryPageIndex}
              queryPageSize={queryPageSize}
              setQueryPageSize={setQueryPageSize}
              isLoading={isLoading}
              error={error}
            />
          </div>
          {/* <div>
            <SentinelAvailableImageriesTable
              sentinelImagery={sentinelImagery}
              selectedImagery={selectedImagery}
              setSentinelImagery={setSentinelImagery}
              setSelectedImagery={setSelectedImagery}
              bbox={bBoxPoints.join(',')}
              renderTCI={renderTCI}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PartialDRBboxDrawer;
