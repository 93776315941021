/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getCloudCover } from 'api/partialdr/subscriptions';

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: '100%',
    // maxHeight: '400px',
    overflow: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  thead: {
    backgroundColor: '#f8f9fa',
  },
  th: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    '&:first-child': {
      paddingLeft: '8px',
    },
    '&:last-child': {
      paddingRight: '8px',
    },
  },
  td: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  tbodyTr: {
    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
  },
  checkbox: {
    padding: '0px',
    marginRight: '8px',
  },
  redRow: {
    backgroundColor: '#FFB0B0',
  },
  greenRow: {
    backgroundColor: '#B0FFB0',
  },
}));

const SentinelAvailableImageriesTable = ({
  sentinelImagery,
  selectedImagery,
  setSentinelImagery,
  setSelectedImagery,
  bbox,
  renderTCI,
  cloudCoveragePercentage,
  loadingPDRData,
  setLoadingPDRData,
}) => {
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [rowData, setRowData] = useState(data);

  const classes = useStyles();

  const cloudCoverage = cloudCoveragePercentage / 100;

  // const handleCheckboxChange = (e, r) => {
  //   if (e.target.checked) {
  //     setSelectedImagery([...selectedImagery, r]);
  //   } else {
  //     setSelectedImagery(selectedImagery.filter((row) => row.id !== r.id));
  //   }
  // };

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, r) => {
    if (e.target.checked) {
      setSelectedImagery([...selectedImagery, r]);
    } else {
      setSelectedImagery(selectedImagery.filter((row) => row.id !== r.id));
    }
    setSelectAll(sentinelImagery.length === selectedImagery.length);
  };

  const fetchData = async (rowsToFetch) => {
    const newData = await Promise.all(
      rowsToFetch.map(async (row) => {
        // Replace this URL with your API endpoint

        const payload = {
          tile: row.SCL,
          bbox,
        };

        try {
          const d = await getCloudCover(payload);
          return { ...row, ...d };
        } catch (error) {
          console.error(error);
          return null;
        }
      })
    );

    setSentinelImagery(
      sentinelImagery
        .map(
          (row) => newData.find((fetchedRow) => fetchedRow.id === row.id) || row
        )
        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
    );
    setLoadingPDRData(false);
  };

  // This will update the selectedRows such that if the row in rowData has problematic_area_percentage <= 0.4 then it will be added to selectedRows
  // useEffect(() => {
  //   setSelectedImagery(
  //     sentinelImagery.filter(
  //       (row) =>
  //         // eslint-disable-next-line no-prototype-builtins
  //         row.hasOwnProperty('problematic_area_percentage') &&
  //         row.problematic_area_percentage <= cloudCoverage
  //     )
  //   );
  // }, [sentinelImagery, cloudCoverage]);

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    setSelectedImagery(e.target.checked ? [...sentinelImagery] : []);
  };

  const formatId = (id) => {
    const parts = id.split('_');
    const date = parts[2];
    const formattedDate = new Date(
      date.slice(0, 4),
      date.slice(4, 6) - 1,
      date.slice(6, 8)
    ).toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
    const tile = parts[1];
    return `${formattedDate}`;
  };

  useEffect(() => {
    const rowsToFetch = sentinelImagery.filter(
      // eslint-disable-next-line no-prototype-builtins
      (row) => !row.hasOwnProperty('problematic_area_percentage')
    );
    if (rowsToFetch.length > 0) {
      fetchData(rowsToFetch);
    }
  }, [sentinelImagery, bbox]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.thead}>
            <TableCell className={classes.th}>
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAllChange}
                className={classes.checkbox}
              />
              Select All
            </TableCell>
            <TableCell className={classes.th}>Cloud Coverage, %</TableCell>
            <TableCell className={classes.th}>S2 Render</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingPDRData ? (
            <TableRow className={classes.tbodyTr}>
              <TableCell align="center" colSpan={4}>
                <CircularProgress color="success" />
              </TableCell>
            </TableRow>
          ) : (
            sentinelImagery
              .filter(
                (row) => row?.problematic_area_percentage <= cloudCoverage
              )
              .map((row) => (
                <TableRow key={row.id} className={classes.tbodyTr}>
                  <TableCell className={classes.td}>
                    <Checkbox
                      checked={selectedImagery.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      onChange={(e) => handleCheckboxChange(e, row)}
                      disabled={
                        !row.hasOwnProperty('problematic_area_percentage')
                      }
                      className={classes.checkbox}
                    />
                    {formatId(row.id)}
                  </TableCell>
                  <TableCell className={classes.td}>
                    {(row.problematic_area_percentage * 100).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        renderTCI(
                          row,
                          bbox.split(',').map((e) => +e)
                        );
                      }}
                    >
                      Show
                    </Button>
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SentinelAvailableImageriesTable;
