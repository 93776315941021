import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, FormControl, TextField, Typography } from '@mui/material';

import SignUpContainer from 'components/SignUpContainer';
import { useAuthContext } from 'contexts/Auth';
import { signInWithEmailPasswordAsync } from 'api/cognito';
import {
  clearErrors,
  startSignIn,
  signInSuccess,
  signInFailure,
} from 'contexts/Auth/actions';
import styles from './authentication.module.css';

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'PasswordResetRequiredException':
      return 'Please reset your password using forgot password button';

    case 'EmailOrPasswordNotFound':
      return 'Incorrect Username / Password';

    default:
      return 'Something went wrong, try again';
  }
};

const SignIn = () => {
  const [authState, authDispatch] = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required'),
  });

  const handleSubmit = async (formData, formikOptions) => {
    formikOptions.setSubmitting(true);
    authDispatch(startSignIn());
    const { email, password } = formData;
    try {
      const response = await signInWithEmailPasswordAsync(email, password);
      if (response.error) {
        if (response.error.code) {
          if (response.error.code === 'UserNotConfirmedException') {
            history.push('/confirm-user', { email });
          }
          response.error.message = getErrorMessage(response.error.code);
        }
        authDispatch(signInFailure(response.error.message));
      } else {
        authDispatch(signInSuccess(response.user));
        if (location.state && location.state.from) {
          history.push(location.state.from);
        } else {
          history.push('/dashboard');
        }
      }
    } catch (error) {
      authDispatch(signInFailure(error.message));
    }
  };

  // clear previous auth errors
  useEffect(() => authDispatch(clearErrors()), []);

  // check active session
  useEffect(() => {
    if (authState.user) {
      history.push('/dashboard');
    }
  }, []);

  return (
    <SignUpContainer>
      <h2>Welcome to Digifarm</h2>
      <Typography color="text.secondary" marginBottom={2}>
        Sign In with your email:
      </Typography>

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <FormControl>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                {...formik.getFieldProps('email')}
                className={styles.signUpInput}
                type="email"
                label="Email"
                variant="filled"
                fullWidth
              />
              <ErrorMessage
                name="email"
                component="div"
                className={styles.validationError}
              />{' '}
              <TextField
                {...formik.getFieldProps('password')}
                className={styles.signUpInput}
                type="password"
                label="Password"
                variant="filled"
                fullWidth
              />
              <ErrorMessage
                name="password"
                component="div"
                className={styles.validationError}
              />
              {/* <Typography marginBottom={3}>
                <Link to="/forgot-password">Forgot your Password?</Link>
              </Typography> */}
              {authState.signInError && (
                <div className={styles.validationError}>
                  {authState.signInError}
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                disabled={
                  !!formik.isSubmitting || !formik.dirty || !formik.isValid
                }
              >
                Sign In
              </Button>
            </Form>
          </FormControl>
        )}
      </Formik>
      {/* <Typography marginTop={2} color="text.grey">
        New to Digifarm? <Link to="/sign-up">Create an Account</Link>
      </Typography> */}
    </SignUpContainer>
  );
};

export default SignIn;
