export const CLEAR_USER = 'CLEAR_USER';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SIGN_UP_INIT = 'SIGN_UP_INIT';

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const CONFIRM_USER_INIT = 'CONFIRM_USER_INIT';

export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';

export const CONFIRM_USER_FAIL = 'CONFIRM_USER_FAIL';

export const RESEND_CONFIRMATION_CODE_INIT = 'RESEND_CONFIRMATION_CODE_INIT';

export const RESEND_CONFIRMATION_CODE_SUCCESS =
  'RESEND_CONFIRMATION_CODE_SUCCESS';

export const RESEND_CONFIRMATION_CODE_FAIL = 'RESEND_CONFIRMATION_CODE_FAIL';

export const SIGN_IN_INIT = 'SIGN_IN_INIT';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const LOAD_CURRENT_USER_INIT = 'LOAD_CURRENT_USER_INIT';

export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS';

export const LOAD_CURRENT_USER_FAILURE = 'LOAD_CURRENT_USER_FAILURE';

export const SIGN_OUT = 'SIGN_OUT';

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const startSignUp = () => ({
  type: SIGN_UP_INIT,
});

export const signUpSuccess = (user) => ({
  type: SIGN_UP_SUCCESS,
  payload: {
    user,
  },
});

export const signUpFailure = (errorMessage) => ({
  type: SIGN_UP_FAILURE,
  payload: {
    errorMessage,
  },
});

export const startConfirmUser = () => ({
  type: CONFIRM_USER_INIT,
});

export const confirmUserSuccess = () => ({
  type: CONFIRM_USER_SUCCESS,
});

export const confirmUserFail = (errorMessage) => ({
  type: CONFIRM_USER_FAIL,
  payload: {
    errorMessage,
  },
});

export const startResendConfirmationCode = () => ({
  type: RESEND_CONFIRMATION_CODE_INIT,
});

export const resendConfirmationCodeSuccess = () => ({
  type: RESEND_CONFIRMATION_CODE_SUCCESS,
});

export const resendConfirmationCodeFail = (errorMessage) => ({
  type: RESEND_CONFIRMATION_CODE_FAIL,
  payload: {
    errorMessage,
  },
});

export const startSignIn = () => ({
  type: SIGN_IN_INIT,
});

export const signInSuccess = (user) => ({
  type: SIGN_IN_SUCCESS,
  payload: {
    user,
  },
});

export const signInFailure = (errorMessage) => ({
  type: SIGN_IN_FAILURE,
  payload: {
    errorMessage,
  },
});

export const startLoadCurrentUser = () => ({
  type: LOAD_CURRENT_USER_INIT,
});

export const loadCurrentUserSuccess = (user) => ({
  type: LOAD_CURRENT_USER_SUCCESS,
  payload: {
    user,
  },
});

export const loadCurrentUserFail = (errorMessage) => ({
  type: LOAD_CURRENT_USER_FAILURE,
  payload: {
    errorMessage,
  },
});

export const signOutUser = () => ({
  type: SIGN_OUT,
});
