/* eslint-disable no-nested-ternary */
// SubscriptionsTable.js
import React, { useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

function TablePaginationActions(props) {
  const theme = useTheme();
  // console.log('ptops', props);
  const { count, page, rowsPerPage, onPageChange, currentPageSize } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={currentPageSize < rowsPerPage}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

const TableView = ({
  columns,
  data = [],
  setQueryPageIndex,
  setQueryPageSize,
  queryPageIndex,
  queryPageSize,
  error,
  isLoading,
}) => {
  const useControlledState = (state) =>
    useMemo(
      () => ({
        ...state,
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      }),
      [state, queryPageIndex, queryPageSize]
    );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data, useControlledState, manualPagination: true },
      usePagination
    );

  const handleChangePage = (event, newPage) => {
    setQueryPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryPageSize(parseInt(event.target.value, 10));
    setQueryPageIndex(0);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        minHeight: '100%',
        padding: '10px 10px',
      }}
    >
      <Table
        {...getTableProps()}
        style={{
          borderCollapse: 'collapse',
        }}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  style={{ borderBottom: '2px solid #CCC', padding: '0.5rem' }}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" component="div">
                    {error?.error ?? 'Server Error.'}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : data.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  style={{
                    borderBottom: '1px solid #EEEFF1',
                  }}
                >
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{
                        padding: '0.5rem',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" component="div">
                    No data found
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[15]}
              colSpan={columns.length}
              rowsPerPage={queryPageSize}
              page={queryPageIndex}
              count={-1}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={(props) => (
                <TablePaginationActions
                  {...props}
                  currentPageSize={data.length}
                />
              )}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const SubscriptionsTable = ({
  columns,
  data,
  isLoading,
  error,
  queryPageIndex,
  setQueryPageIndex,
  queryPageSize,
  setQueryPageSize,
}) => (
  <TableView
    columns={columns}
    data={data}
    queryPageIndex={queryPageIndex}
    setQueryPageIndex={setQueryPageIndex}
    queryPageSize={queryPageSize}
    setQueryPageSize={setQueryPageSize}
    isLoading={isLoading}
    error={error}
  />
);

export default SubscriptionsTable;
