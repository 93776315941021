import { createContext, useContext, useMemo, useReducer } from 'react';
import authReducer from './reducer';

const AuthContext = createContext();

const initialUserState = {
  user: null,
  isValidatingSession: true,
  isLoading: false,

  signUpError: null,
  confirmUserError: null,
  resendConfirmationCodeError: null,
  signInError: null,
  forgotPasswordError: null,
  resetPasswordError: null,
};

const useAuthContextState = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUserContext must be used within UserContextProvider');
  }

  const [state] = context;

  return state;
};

const useAuthContextDispatch = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUserContext must be used within UserContextProvider');
  }

  const [, dispatch] = context;

  return dispatch;
};

const useAuthContext = () => [useAuthContextState(), useAuthContextDispatch()];

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialUserState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <AuthContext.Provider value={value} {...props} />;
};

export {
  AuthProvider,
  useAuthContextState,
  useAuthContextDispatch,
  useAuthContext,
};
