import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: {
              from: props.location.pathname,
            },
          }}
        />
      )
    }
  />
);

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authUser: PropTypes.shape({
    email: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default AuthRoute;
