import styles from 'pages/QuickDR/mapview.module.css';
import { Link, useLocation } from 'react-router-dom';
import { BusinessIcon, PDRIcon, TokenIcon, ArrowRightIcon } from 'utils/icons';
import CreditPanel from '../CreditPanel';
// import TokenPanel from '../TokenPanel';

const SidePanelMenu = () => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.leftPanel}>
      <div className={`${styles.leftMenu} ${styles.topContainer}`}>
        <Link
          className={`${styles.menuItem} ${
            location.pathname === '/dashboard' ? styles.active : ''
          }`}
          to="/dashboard"
        >
          <BusinessIcon
            className="icon-root"
            stroke={
              location.pathname === '/dashboard' ? '#01b075' : 'currentColor'
            }
          />
          <div className={styles.menuLabel}>Main Dashboard</div>
          {location.pathname === '/dashboard' && (
            <ArrowRightIcon className={styles.arrow} />
          )}
        </Link>
        <Link
          className={`${styles.menuItem} ${
            location.pathname === '/quick-dr/subscriptions' ? styles.active : ''
          } ${styles.borderLink}`}
          to="/quick-dr/subscriptions"
        >
          <PDRIcon
            className="icon-root"
            stroke={
              location.pathname === '/quick-dr/subscriptions'
                ? '#01b075'
                : 'currentColor'
            }
          />
          <div className={styles.menuLabel}>Deep Resolution Imagery</div>
          {location.pathname === '/quick-dr/subscriptions' && (
            <ArrowRightIcon className={styles.arrow} />
          )}
        </Link>

        {/* <Link
          className={`${styles.menuItem} ${
            location.pathname === '/token-management' ? styles.active : ''
          }`}
          to="/token-management"
        >
          <TokenIcon
            className="icon-root"
            stroke={
              location.pathname === '/token-management'
                ? '#01b075'
                : 'currentColor'
            }
          />
          <div className={styles.menuLabel}>Token Management</div>
          {location.pathname === '/token-management' && (
            <ArrowRightIcon className={styles.arrow} />
          )}
        </Link> */}
      </div>

      <div className={`${styles.leftMenu} ${styles.bottomContainer}`}>
        <CreditPanel className={styles.creditPanel} />
      </div>

      <div className={styles.copyRight}>&copy; {currentYear}, DigiFarm</div>
    </div>
  );
};

export default SidePanelMenu;
