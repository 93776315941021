// import SphericalMercator from "@mapbox/sphericalmercator";
import tilebelt from '@mapbox/tilebelt';

const BASEMAP_BASIC_URL =
  'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4graatone&zoom={z}&x={x}&y={y}';
function addBaseMapLayers(map) {
  map.addLayer({
    id: 'basemap_sat2',
    source: 'basemap_sat2',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
    layout: { visibility: 'visible' },
  });
  map.addLayer({
    id: 'iowa_sioux_basemap',
    source: 'iowa_sioux_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
    layout: { visibility: 'visible' },
  });
  map.addLayer({
    id: 'punjab_india_basemap',
    source: 'punjab_india_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
    layout: { visibility: 'visible' },
  });
  map.addLayer({
    id: 'czech_basemap',
    source: 'czech_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
    layout: { visibility: 'visible' },
  });
  map.addLayer({
    id: 'brazil_basemap',
    source: 'brazil_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'poland_basemap',
    source: 'poland_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'punjab_43rfq_basemap',
    source: 'punjab_43rfq_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });

  map.addLayer({
    id: 'germany_basemap',
    source: 'germany_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'belgium_basemap',
    source: 'belgium_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'austria_basemap',
    source: 'austria_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'italy_basemap',
    source: 'italy_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'milan_basemap',
    source: 'milan_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'tulare_basemap',
    source: 'tulare_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'saskatchewan_basemap',
    source: 'saskatchewan_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'switzerland_basemap',
    source: 'switzerland_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  // map.addLayer({
  //   id: "czech_basemap",
  //   source: "czech_basemap",
  //   type: "raster",
  //   minzoom: 0,
  //   maxzoom: 24,
  // });
  map.addLayer({
    id: 'denmark_basemap',
    source: 'denmark_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'suphanburi_basemap',
    source: 'suphanburi_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'france_basemap',
    source: 'france_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'hungary_basemap',
    source: 'hungary_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'england_basemap',
    source: 'england_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'bulgaria_basemap',
    source: 'bulgaria_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'netherlands_basemap',
    source: 'netherlands_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });

  map.addLayer({
    id: 'egypt_35rqq_basemap',
    source: 'egypt_35rqq_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'egypt_36rvp_basemap',
    source: 'egypt_36rvp_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });
  map.addLayer({
    id: 'arizona_11sqs_basemap',
    source: 'arizona_11sqs_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });

  map.addLayer({
    id: 'mexico_basemap',
    source: 'mexico_basemap',
    type: 'raster',
    minzoom: 0,
    maxzoom: 24,
  });

  // map.showTileBoundaries = true;
  // map.showCollisionBoxes = true;
}

function turnOffBaseMapLayers(map) {
  // do this to all layers
  map.setLayoutProperty('basemap_sat2', 'visibility', 'none');
  map.setLayoutProperty('iowa_sioux_basemap', 'visibility', 'none');
  map.setLayoutProperty('punjab_india_basemap', 'visibility', 'none');
  map.setLayoutProperty('czech_basemap', 'visibility', 'none');
  map.setLayoutProperty('brazil_basemap', 'visibility', 'none');
  map.setLayoutProperty('poland_basemap', 'visibility', 'none');
  map.setLayoutProperty('punjab_43rfq_basemap', 'visibility', 'none');
  // map.setLayoutProperty("norway_basemap", "visibility", "none");
  map.setLayoutProperty('germany_basemap', 'visibility', 'none');
  map.setLayoutProperty('belgium_basemap', 'visibility', 'none');
  map.setLayoutProperty('austria_basemap', 'visibility', 'none');
  map.setLayoutProperty('italy_basemap', 'visibility', 'none');
  map.setLayoutProperty('milan_basemap', 'visibility', 'none');
  map.setLayoutProperty('tulare_basemap', 'visibility', 'none');
  map.setLayoutProperty('saskatchewan_basemap', 'visibility', 'none');
  map.setLayoutProperty('switzerland_basemap', 'visibility', 'none');
  map.setLayoutProperty('denmark_basemap', 'visibility', 'none');
  map.setLayoutProperty('suphanburi_basemap', 'visibility', 'none');
  map.setLayoutProperty('france_basemap', 'visibility', 'none');
  map.setLayoutProperty('hungary_basemap', 'visibility', 'none');
  map.setLayoutProperty('england_basemap', 'visibility', 'none');
  map.setLayoutProperty('bulgaria_basemap', 'visibility', 'none');
  map.setLayoutProperty('netherlands_basemap', 'visibility', 'none');
  map.setLayoutProperty('mexico_basemap', 'visibility', 'none');
}
function turnOnBaseMapLayers(map) {
  // do this to all layers
  map.setLayoutProperty('basemap_sat2', 'visibility', 'visible');
  map.setLayoutProperty('iowa_sioux_basemap', 'visibility', 'visible');
  map.setLayoutProperty('punjab_india_basemap', 'visibility', 'visible');
  map.setLayoutProperty('czech_basemap', 'visibility', 'visible');
  map.setLayoutProperty('brazil_basemap', 'visibility', 'visible');
  map.setLayoutProperty('poland_basemap', 'visibility', 'visible');
  map.setLayoutProperty('punjab_43rfq_basemap', 'visibility', 'visible');
  // map.setLayoutProperty("norway_basemap", "visibility", "visible");
  map.setLayoutProperty('germany_basemap', 'visibility', 'visible');
  map.setLayoutProperty('belgium_basemap', 'visibility', 'visible');
  map.setLayoutProperty('austria_basemap', 'visibility', 'visible');
  map.setLayoutProperty('italy_basemap', 'visibility', 'visible');
  map.setLayoutProperty('milan_basemap', 'visibility', 'visible');
  map.setLayoutProperty('tulare_basemap', 'visibility', 'visible');
  map.setLayoutProperty('saskatchewan_basemap', 'visibility', 'visible');
  map.setLayoutProperty('switzerland_basemap', 'visibility', 'visible');
  map.setLayoutProperty('denmark_basemap', 'visibility', 'visible');
  map.setLayoutProperty('suphanburi_basemap', 'visibility', 'visible');
  map.setLayoutProperty('france_basemap', 'visibility', 'visible');
  map.setLayoutProperty('hungary_basemap', 'visibility', 'visible');
  map.setLayoutProperty('england_basemap', 'visibility', 'visible');
  map.setLayoutProperty('bulgaria_basemap', 'visibility', 'visible');
  map.setLayoutProperty('netherlands_basemap', 'visibility', 'visible');
  map.setLayoutProperty('mexico_basemap', 'visibility', 'visible');
}

const mapStyle = {
  version: 8,
  sources: {
    mapbox: {
      type: 'raster',
      url: 'mapbox://mapbox.satellite',
    },
    'mapbox-streets': {
      url: 'mapbox://mapbox.mapbox-streets-v8',
      type: 'vector',
    },
    basemap_basic: {
      type: 'raster',
      tiles: [BASEMAP_BASIC_URL],
      tileSize: 256,
    },
    basemap_sat2: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/datasets/norway_orto_v1/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800',
      ],
      tileSize: 256,
      bounds: [
        10.27741236915756, 60.562768622993445, 11.840852714501409,
        60.94270674642959,
      ],
    },
    iowa_sioux_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data/hr/us/us_io_cnh_gs_1_cog.tif',
      ],
      tileSize: 512,
      bounds: [
        -97.9738290057744, 41.91627585152915, -93.86449355652627,
        43.09486553253598,
      ],
    },
    punjab_india_basemap: {
      type: 'raster',
      tiles: [
        `https://rasters.digifarm.io/v1/rasters/datasets/bayer_india_gs2/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800`,
      ],
      tileSize: 256,
      bounds: [
        76.39335871622285, 30.893985857608854, 76.56694188932494,
        30.994603506042452,
      ],
    },
    czech_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/CZ/33UVR/S2_DR_T33UVR_20210617_0_rgbn_cog.tif&render=rgbn_rgb',
        //
      ],
      tileSize: 256,
      bounds: [
        13.976738500423636, 50.27419730046995, 14.27362678967998,
        50.366557833910804,
      ],
    },
    brazil_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800' +
          '&filename=digi-data-v1/s2/dr/BR/21LYC/S2_DR_T21LYC_20210329_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      bounds: [
        -56.079750882427575, -15.852090599283218, -53.70790847716806,
        -15.25048902255923,
      ],
    },
    poland_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800' +
          '&filename=digi-data-v1/s2/dr/PL/33UXT/S2_DR_T33UXT_20210609_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      bounds: [
        16.999945363810127, 51.663479229296456, 17.097223088362853,
        51.704013390375394,
      ],
    },
    punjab_43rfq_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800' +
          '&filename=digi-data-v1/s2/dr/IN/43RFQ/S2_DR_T43RFQ_20211105_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      bounds: [
        76.65113778274326, 30.929266718724662, 76.66092330876097,
        30.935317185551853,
      ],
    },
    norway_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/NO/32VPN/S2_DR_T32VPN_20200919_rgbn.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        10.990990557539817, 60.68908840357494, 11.307863696935982,
        60.80153442213208,
      ],
    },
    germany_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/DE/32UND/S2_DR_T32UND_20210618_rgbn.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        9.968411112332461, 52.21529431368526, 10.247957381237427,
        52.33437773412081,
      ],
    },
    belgium_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/BE/31UES/S2_DR_T31UES_20220719_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        3.0341704812626347, 50.85786063012816, 3.0924066033940676,
        50.884428501569346,
      ],
    },
    austria_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/AT/33UWP/S2_DR_T33UWP_20210724_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        15.486629393873102, 48.11358707025758, 15.975326556569087,
        48.327018025975576,
      ],
    },
    italy_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/IT/32TQN/S2_DR_T32TQN_20200917_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        11.832688886883716, 43.02707871734063, 11.97873905804127,
        43.099033100416534,
      ],
    },
    milan_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/IT/32TNR/S2_DR_T32TNR_20200406_rgbn.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        8.81122012583478, 45.25788940851356, 9.278418093862172,
        45.48581780091456,
      ],
    },
    tulare_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/US/11SKA/S2_DR_T11SKA_20210720_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        -119.53680869654569, 36.08946034016131, -119.30457570506414,
        36.21984249530388,
      ],
    },
    saskatchewan_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/CA/13UES/S2_DR_T13UES_20210613_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        -103.80025436589173, 51.1931908859747, -103.40175635489591,
        51.37959945203818,
      ],
    },
    switzerland_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/CH/31TGM/S2_DR_T31TGM_20210529_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        6.235183380629451, 46.43876935571092, 6.667902930789893,
        46.64416510991171,
      ],
    },
    denmark_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/DK/32VNH/S2_DR_T32VNH_20210716_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        9.504904235589066, 56.248496666194086, 9.949219097274266,
        56.420052561846205,
      ],
    },
    suphanburi_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800' +
          '&filename=digi-data-v1/s2/dr/TH/47PPS/S2_DR_T47PPS_20210521_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      bounds: [
        100.37561918835615, 15.026804531815301, 100.46768081167232,
        15.07681253380126,
      ],
    },
    france_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/FR/30UXV/S2_DR_T30UXV_20210531_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        -0.3926917512560806, 48.97282149112618, -0.08531070166407062,
        49.113050962966526,
      ],
    },
    hungary_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/HU/33TYN/S2_DR_T33TYN_20210810_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        17.92886232706593, 46.830681881088, 18.668573307995047,
        47.178504465849585,
      ],
    },
    england_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/GB/30UXC/S2_DR_T30UXC_20210531_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        -0.913396349838564, 51.65414926126533, -0.4611660247116447,
        51.847732159932235,
      ],
    },
    bulgaria_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/BG/34TGN/S2_DR_T34TGN_20210727_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        23.422684822826483, 42.60836167314898, 23.73154667484829,
        42.7645747405318,
      ],
    },
    netherlands_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/NL/31UFU/S2_DR_T31UFU_20210530_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        5.53038765023112, 52.45201308913039, 5.718750490452237,
        52.53226952443359,
      ],
    },
    egypt_35rqq_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/EG/35RQQ/S2_DR_T35RQQ_20211107_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        29.559041452513185, 30.7675901036539, 29.67012599459113,
        30.823771803681637,
      ],
    },
    egypt_36rvp_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/EG/36RVP/S2_DR_T36RVP_20211201_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        32.43382713339841, 25.59230266402193, 32.61972448918422,
        25.703143826878843,
      ],
    },
    arizona_11sqs_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=digi-data-v1/s2/dr/US/11SQS/S2_DR_T11SQS_20210318_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      tileSize: 512,
      bounds: [
        -114.77997359497918, 32.57531070680537, -114.67092237234668,
        32.64121937798889,
      ],
    },
    mexico_basemap: {
      type: 'raster',
      tiles: [
        'https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800' +
          '&filename=digi-data-v1/s2/dr/MX/13QGB/S2_DR_T13QGB_20210519_0_rgbn_cog.tif&render=rgbn_rgb',
      ],
      bounds: [
        -102.23280868996288, 18.89083360203084, -102.04740476487353,
        18.97733226773147,
      ],
    },
  },
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sprite:
    'mapbox://sprites/girishpallagatti/cl45u8192000414pmy8oydqbg/efa4z7hq1xkpmov5bzti9h7f2',
  layers: [
    {
      id: 'mapbox',
      source: 'mapbox',
      type: 'raster',
      maxzoom: 19,
    },
    {
      id: 'basemap_basic',
      source: 'basemap_basic',
      type: 'raster',
      minzoom: 0,
      maxzoom: 24,
      layout: { visibility: 'none' },
    },
  ],
};

const locationObj = {
  delineatedFields: {
    norway: {
      center: [11.1804734, 60.746268],
      name: 'Hamar, Norway',
      country_code: 'no',
    },
    iowa: {
      center: [-96.2173, 42.5226],
      name: 'Sioux City, Iowa',
      country_code: 'us',
    },
    germany: {
      center: [10.1151, 52.2768],
      name: 'Germany',
      country_code: 'de',
    },
    belgium: {
      center: [3.063288542, 50.871144566],
      name: 'Belgium',
      country_code: 'be',
      mgrs: '31UDS',
    },
    austria: {
      center: [15.76011, 48.21714],
      zoom: 13,
      name: 'Austria',
      country_code: 'at',
      mgrs: '33UWP',
    },
    italy: { center: [11.90099, 43.06147], name: 'Italy', country_code: 'it' },
    milan: {
      center: [9.06831356, 45.366777837],
      name: 'Milan, Italy',
      country_code: 'it',
    },
    tulare: {
      center: [-119.4085, 36.1532],
      name: 'Tulare, California',
      country_code: 'us',
    },
    saskatchewan: {
      center: [-103.64431, 51.26385],
      zoom: 14,
      name: 'Saskatchewan, Canada',
      country_code: 'ca',
    },
    switzerland: {
      center: [6.45, 46.53],
      name: 'Switzerland',
      country_code: 'ch',
    },
    czech: {
      center: [14.125182645, 50.320377567],
      zoom: 12.4,
      name: 'Czech Republic',
      country_code: 'cz',
    },
    denmark: {
      center: [9.70948, 56.33231],
      name: 'Denmark',
      country_code: 'dk',
    },
    newzealand: {
      center: [172.05914, -43.88638],
      zoom: 13.33,
      name: 'New Zealand',
      country_code: 'nz',
    },
    punjab: {
      zoom: 16.6,
      center: [76.655809, 30.932539],
      name: 'Punjab, India',
      country_code: 'in',
    },
    suphanburi: {
      center: [100.42462, 15.05134],
      zoom: 14.5,
      name: 'In Buri, Thailand',
      country_code: 'th',
    },
    france: {
      center: [-0.23497, 49.04343],
      zoom: 12.92,
      name: 'France',
      country_code: 'fr',
    },
    hungary: {
      center: [18.3015, 47.0065],
      zoom: 11,
      name: 'Hungary',
      country_code: 'hu',
    },
    england: {
      center: [-0.6861, 51.7509],
      zoom: 11.59,
      name: 'United Kingdom',
      country_code: 'gb',
    },
    bulgaria: {
      center: [23.57411, 42.68841],
      zoom: 12.26,
      name: 'Bulgaria',
      country_code: 'bg',
    },
    netherlands: {
      center: [5.63017, 52.49108],
      zoom: 13.3,
      name: 'Netherlands',
      country_code: 'nl',
      mgrs: '31UFU',
    },
    poland: {
      center: [17.05136, 51.68528],
      zoom: 14.8,
      name: 'Poland',
      country_code: 'pl',
    },
    brazil: {
      center: [-54.89431, -15.45116],
      zoom: 12.16,
      name: 'Mato Grosso, Brazil',
      country_code: 'br',
    },
    mexico: {
      center: [-102.13818, 18.947],
      zoom: 13.93,
      name: 'Michoacán, Mexico',
      country_code: 'mx',
    },
  },
  deepResImages: {
    norway: {
      center: [11.18047, 60.74627],
      name: 'Hamar, Norway',
      country_code: 'no',
    },
    iowa: {
      center: [-96.2173, 42.5226],
      name: 'Sioux City, Iowa',
      country_code: 'us',
    },
    germany: {
      center: [10.1151, 52.2768],
      name: 'Germany',
      country_code: 'de',
    },
    belgium: {
      center: [2.48342, 50.83249],
      name: 'Belgium',
      country_code: 'be',
      mgrs: '31UDS',
    },
    austria: {
      center: [15.76011, 48.21714],
      zoom: 13,
      name: 'Austria',
      country_code: 'at',
      mgrs: '33UWP',
    },
    italy: { center: [11.90099, 43.06147], name: 'Italy', country_code: 'it' },
    milan: {
      center: [9.06831356, 45.366777837],
      name: 'Milan, Italy',
      country_code: 'it',
    },
    tulare: {
      center: [-119.4085, 36.1532],
      name: 'Tulare, California',
      country_code: 'us',
    },
    saskatchewan: {
      center: [-109.5701, 51.5225],
      zoom: 13,
      name: 'Saskatchewan, Canada',
      country_code: 'ca',
    },
    switzerland: {
      center: [6.45, 46.53],
      name: 'Switzerland',
      country_code: 'ch',
    },
    czech: {
      center: [14.125182645, 50.320377567],
      zoom: 12.4,
      name: 'Czech Republic',
      country_code: 'cz',
    },
    denmark: {
      center: [9.70948, 56.33231],
      name: 'Denmark',
      country_code: 'dk',
    },
    punjab: {
      zoom: 16.6,
      center: [76.655809, 30.932539],
      name: 'Punjab, India',
      country_code: 'in',
    },
    suphanburi: {
      center: [100.42462, 15.05134],
      zoom: 14.5,
      name: 'In Buri, Thailand',
      country_code: 'th',
    },
    france: {
      center: [-0.23497, 49.04343],
      zoom: 12.92,
      name: 'France',
      country_code: 'fr',
    },
    hungary: {
      center: [18.3015, 47.0065],
      zoom: 11,
      name: 'Hungary',
      country_code: 'hu',
    },
    england: {
      center: [-0.6861, 51.7509],
      zoom: 11.59,
      name: 'United Kingdom',
      country_code: 'gb',
    },
    bulgaria: {
      center: [23.57411, 42.68841],
      zoom: 12.26,
      name: 'Bulgaria',
      country_code: 'bg',
    },
    netherlands: {
      center: [5.63017, 52.49108],
      zoom: 13.3,
      name: 'Netherlands',
      country_code: 'nl',
      mgrs: '31UFU',
    },
    poland: {
      center: [17.05136, 51.68528],
      zoom: 14.8,
      name: 'Poland',
      country_code: 'pl',
    },
    brazil: {
      center: [-54.89431, -15.45116],
      zoom: 12.16,
      name: 'Mato Grosso, Brazil',
      country_code: 'br',
    },
    mexico: {
      center: [-102.13818, 18.947],
      zoom: 13.93,
      name: 'Michoacán, Mexico',
      country_code: 'mx',
    },
  },
  timeSeries: {
    arizona: {
      center: [-114.72274, 32.6123],
      zoom: 13.7,
      name: 'Somerton, Arizona',
      country_code: 'us',
    },
    egypt_1: {
      center: [29.642966, 30.799378],
      name: 'New Borg El-Arab, Egypt',
      zoom: 15,
      country_code: 'eg',
    },
    egypt_2: {
      center: [32.514728, 25.628669],
      name: 'Luxor, Egypt',
      zoom: 15.56,
      country_code: 'eg',
    },
    estonia: {
      center: [26.99815, 58.28319],
      zoom: 13.12,
      name: 'Estonia',
      country_code: 'ee',
    },
  },
  partialDR: {
    norway: {
      center: [11.1804734, 60.746268],
      name: 'Hamar, Norway',
      country_code: 'no',
    },
  },
};

function getTilesForCurrentBounds(map, tileBounds) {
  // console.log('getTilesForCurrentBounds');
  // var merc = new SphericalMercator({
  //   size: 256,
  //   // antimeridian: true
  // });

  // console.log();

  const b = map.getBounds();

  // eslint-disable-next-line no-underscore-dangle
  const bounds = tileBounds || [b._sw.lng, b._sw.lat, b._ne.lng, b._ne.lat];

  // [w, s, e, n]
  // doesnt' seem to work this way. Gotta see a example somewhere

  // -180,-85.05112877980659,180,85.0511287798066
  // console.log(
  //   "xyz",
  //   merc.xyz(
  //     [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat],
  //     map.getZoom()
  //   )
  // );

  const l2tiles = tilebelt.getChildren(tilebelt.bboxToTile(bounds));

  const l3tiles = l2tiles.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  // console.log("l3tiles", l3tiles);

  const isTileZoomPlus1 = l3tiles[0][2] < map.getZoom();

  const l4tiles = isTileZoomPlus1
    ? l3tiles.reduce((acc, tile) => {
        const children = tilebelt.getChildren(tile);
        return acc.concat(children);
      }, [])
    : l3tiles;

  // Two options -
  // 1. get point to tile (from the center/location of the map map.getcenter or center of the bbox) and then get siblings. That might be enough. If not one more round of siblings of all the corner tiles and then select distinct tiles of that. Get the tiles for those many.
  // 2. use the min max and get all the tiles between them

  // console.log("bbox", merc.bbox(8701, 4690, 14));
  const center = map.getCenter();

  const centerTile = tilebelt.pointToTile(
    center.lng,
    center.lat,
    Math.round(map.getZoom()) + 1
  );

  const parentTile = tilebelt.getParent(
    tilebelt.getParent(tilebelt.getParent(centerTile))
  );

  const childrenTiles = tilebelt.getChildren(parentTile);
  const childrenTilesl2 = childrenTiles.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  const childrenTilesl3 = childrenTilesl2.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  // If the getbounds was working could have used the l3/l4 tiles. Since that doesn't seem to be working properly, using the center tile and then getting parents of that and then getting children of that.
  return childrenTilesl3;
}

function addPlaceLabelLayers(map) {
  map.addLayer({
    id: 'admin-1-boundary-bg',
    type: 'line',
    source: 'mapbox-streets',
    'source-layer': 'admin',
    minzoom: 7,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 1],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        'hsl(0, 0%, 14%)',
        16,
        'hsl(0, 0%, 30%)',
      ],
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 3.75, 12, 5.5],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.75],
      'line-dasharray': [1, 0],
      'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 8, 3],
    },
    metadata: {
      'mapbox:featureComponent': 'admin-boundaries',
      'mapbox:group': 'Administrative boundaries, admin',
    },
  });
  map.addLayer({
    id: 'admin-0-boundary-bg',
    type: 'line',
    source: 'mapbox-streets',
    'source-layer': 'admin',
    minzoom: 1,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 5.25, 10, 12],
      'line-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        6,
        'hsl(0, 0%, 14%)',
        8,
        'hsl(0, 0%, 30%)',
      ],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 4, 0.5],
      'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 10, 3],
    },
    metadata: {
      'mapbox:featureComponent': 'admin-boundaries',
      'mapbox:group': 'Administrative boundaries, admin',
    },
  });
  map.addLayer({
    minzoom: 2,
    layout: { 'line-cap': 'round' },
    metadata: {
      'mapbox:featureComponent': 'admin-boundaries',
      'mapbox:group': 'Administrative boundaries, admin',
    },
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 1],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    type: 'line',
    source: 'mapbox-streets',
    id: 'admin-1-boundary',
    paint: {
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [2, 0]],
        7,
        ['literal', [2, 2, 6, 2]],
      ],
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.75, 12, 1.5],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 1],
      'line-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        'hsl(0, 0%, 0%)',
        7,
        'hsl(0, 0%, 5%)',
      ],
    },
    'source-layer': 'admin',
  });
  map.addLayer({
    minzoom: 1,
    layout: { 'line-cap': 'round' },
    metadata: {
      'mapbox:featureComponent': 'admin-boundaries',
      'mapbox:group': 'Administrative boundaries, admin',
    },
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'disputed'], 'false'],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    type: 'line',
    source: 'mapbox-streets',
    id: 'admin-0-boundary',
    paint: {
      'line-color': 'hsl(0, 0%, 0%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.75, 10, 3],
      'line-dasharray': [10, 0],
    },
    'source-layer': 'admin',
  });
  map.addLayer({
    id: 'admin-0-boundary-disputed',
    type: 'line',
    source: 'mapbox-streets',
    'source-layer': 'admin',
    minzoom: 1,
    filter: [
      'all',
      ['==', ['get', 'disputed'], 'true'],
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-color': 'hsl(0, 0%, 0%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.75, 10, 3],
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [3.25, 3.25]],
        6,
        ['literal', [2.5, 2.5]],
        7,
        ['literal', [2, 2.25]],
        8,
        ['literal', [1.75, 2]],
      ],
    },
    metadata: {
      'mapbox:featureComponent': 'admin-boundaries',
      'mapbox:group': 'Administrative boundaries, admin',
    },
  });
  map.addLayer({
    minzoom: 10,
    layout: {
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-transform': 'uppercase',
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-letter-spacing': ['match', ['get', 'type'], 'suburb', 0.15, 0.1],
      'text-max-width': 7,
      'text-padding': 3,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.5, 0, 1, 1],
        ['zoom'],
        11,
        ['match', ['get', 'type'], 'suburb', 11, 10.5],
        15,
        ['match', ['get', 'type'], 'suburb', 15, 14],
      ],
    },
    metadata: {
      'mapbox:featureComponent': 'place-labels',
      'mapbox:group': 'Place labels, place-labels',
    },
    maxzoom: 15,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        'settlement_subdivision',
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        'disputed_settlement_subdivision',
        [
          'all',
          ['==', ['get', 'disputed'], 'true'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        ],
        false,
      ],
      ['<=', ['get', 'filterrank'], 4],
    ],
    type: 'symbol',
    source: 'mapbox-streets',
    id: 'settlement-subdivision-label',
    paint: {
      'text-halo-color': 'hsla(0, 5%, 0%, 0.75)',
      'text-halo-width': 1,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-blur': 0.5,
    },
    'source-layer': 'place_label',
  });
  map.addLayer({
    minzoom: 3,
    layout: {
      'text-line-height': 1.1,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.9, 1],
        ['zoom'],
        3,
        [
          'step',
          ['get', 'symbolrank'],
          12,
          9,
          11,
          10,
          10.5,
          12,
          9.5,
          14,
          8.5,
          16,
          6.5,
          17,
          4,
        ],
        13,
        [
          'step',
          ['get', 'symbolrank'],
          23,
          9,
          21,
          10,
          19,
          11,
          17,
          12,
          16,
          13,
          15,
          15,
          13,
        ],
      ],
      'text-radial-offset': [
        'step',
        ['zoom'],
        ['match', ['get', 'capital'], 2, 0.6, 0.55],
        8,
        0,
      ],
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'capital'], 2],
          'border-dot-13',
          ['step', ['get', 'symbolrank'], 'dot-11', 9, 'dot-10', 11, 'dot-9'],
        ],
        8,
        '',
      ],
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-justify': 'auto',
      'text-anchor': ['step', ['zoom'], ['get', 'text_anchor'], 8, 'center'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-max-width': 7,
    },
    metadata: {
      'mapbox:featureComponent': 'place-labels',
      'mapbox:group': 'Place labels, place-labels',
    },
    maxzoom: 13,
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 3],
      [
        'match',
        ['get', 'class'],
        'settlement',
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        'disputed_settlement',
        [
          'all',
          ['==', ['get', 'disputed'], 'true'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        ],
        false,
      ],
      [
        'step',
        ['zoom'],
        ['>', ['get', 'symbolrank'], 6],
        4,
        ['>=', ['get', 'symbolrank'], 7],
        6,
        ['>=', ['get', 'symbolrank'], 8],
        7,
        ['>=', ['get', 'symbolrank'], 10],
        10,
        ['>=', ['get', 'symbolrank'], 11],
        11,
        ['>=', ['get', 'symbolrank'], 13],
        12,
        ['>=', ['get', 'symbolrank'], 15],
      ],
    ],
    type: 'symbol',
    source: 'mapbox-streets',
    id: 'settlement-minor-label',
    paint: {
      'text-color': 'hsl(0, 0%, 95%)',
      'text-halo-color': 'hsl(0, 5%, 0%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
    'source-layer': 'place_label',
  });
  map.addLayer({
    minzoom: 3,
    layout: {
      'text-line-height': 1.1,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.9, 1],
        ['zoom'],
        3,
        ['step', ['get', 'symbolrank'], 13, 6, 12],
        6,
        ['step', ['get', 'symbolrank'], 16, 6, 15, 7, 14],
        8,
        ['step', ['get', 'symbolrank'], 18, 9, 17, 10, 15],
        15,
        [
          'step',
          ['get', 'symbolrank'],
          23,
          9,
          22,
          10,
          20,
          11,
          18,
          12,
          16,
          13,
          15,
          15,
          13,
        ],
      ],
      'text-radial-offset': [
        'step',
        ['zoom'],
        ['match', ['get', 'capital'], 2, 0.6, 0.55],
        8,
        0,
      ],
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'capital'], 2],
          'border-dot-13',
          ['step', ['get', 'symbolrank'], 'dot-11', 9, 'dot-10', 11, 'dot-9'],
        ],
        8,
        '',
      ],
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-justify': [
        'step',
        ['zoom'],
        [
          'match',
          ['get', 'text_anchor'],
          ['left', 'bottom-left', 'top-left'],
          'left',
          ['right', 'bottom-right', 'top-right'],
          'right',
          'center',
        ],
        8,
        'center',
      ],
      'text-anchor': ['step', ['zoom'], ['get', 'text_anchor'], 8, 'center'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-max-width': 7,
    },
    metadata: {
      'mapbox:featureComponent': 'place-labels',
      'mapbox:group': 'Place labels, place-labels',
    },
    maxzoom: 15,
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 3],
      [
        'match',
        ['get', 'class'],
        'settlement',
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        'disputed_settlement',
        [
          'all',
          ['==', ['get', 'disputed'], 'true'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        ],
        false,
      ],
      [
        'step',
        ['zoom'],
        false,
        3,
        ['<=', ['get', 'symbolrank'], 6],
        4,
        ['<', ['get', 'symbolrank'], 7],
        6,
        ['<', ['get', 'symbolrank'], 8],
        7,
        ['<', ['get', 'symbolrank'], 10],
        10,
        ['<', ['get', 'symbolrank'], 11],
        11,
        ['<', ['get', 'symbolrank'], 13],
        12,
        ['<', ['get', 'symbolrank'], 15],
        13,
        ['>=', ['get', 'symbolrank'], 11],
        14,
        ['>=', ['get', 'symbolrank'], 15],
      ],
    ],
    type: 'symbol',
    source: 'mapbox-streets',
    id: 'settlement-major-label',
    paint: {
      'text-color': 'hsl(0, 0%, 95%)',
      'text-halo-color': 'hsl(0, 5%, 0%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    },
    'source-layer': 'place_label',
  });
  map.addLayer({
    minzoom: 3,
    layout: {
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.85, 0.7, 0.65, 1],
        ['zoom'],
        4,
        ['step', ['get', 'symbolrank'], 10, 6, 9.5, 7, 9],
        9,
        ['step', ['get', 'symbolrank'], 21, 6, 16, 7, 13],
      ],
      'text-transform': 'uppercase',
      'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
      'text-field': [
        'step',
        ['zoom'],
        [
          'step',
          ['get', 'symbolrank'],
          ['coalesce', ['get', 'name_en'], ['get', 'name']],
          5,
          ['coalesce', ['get', 'abbr'], ['get', 'name_en'], ['get', 'name']],
        ],
        5,
        ['coalesce', ['get', 'name_en'], ['get', 'name']],
      ],
      'text-letter-spacing': 0.15,
      'text-max-width': 6,
    },
    metadata: {
      'mapbox:featureComponent': 'place-labels',
      'mapbox:group': 'Place labels, place-labels',
    },
    maxzoom: 9,
    filter: [
      'match',
      ['get', 'class'],
      'state',
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      'disputed_state',
      [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      false,
    ],
    type: 'symbol',
    source: 'mapbox-streets',
    id: 'state-label',
    paint: {
      'text-color': 'hsl(0, 0%, 95%)',
      'text-halo-color': 'hsl(0, 5%, 0%)',
      'text-halo-width': 1,
    },
    'source-layer': 'place_label',
  });
  map.addLayer({
    minzoom: 1,
    layout: {
      'icon-image': '',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-line-height': 1.1,
      'text-max-width': 6,
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-radial-offset': ['step', ['zoom'], 0.6, 8, 0],
      'text-justify': [
        'step',
        ['zoom'],
        [
          'match',
          ['get', 'text_anchor'],
          ['left', 'bottom-left', 'top-left'],
          'left',
          ['right', 'bottom-right', 'top-right'],
          'right',
          'center',
        ],
        7,
        'auto',
      ],
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.7, 1],
        ['zoom'],
        1,
        ['step', ['get', 'symbolrank'], 11, 4, 9, 5, 8],
        9,
        ['step', ['get', 'symbolrank'], 22, 4, 19, 5, 17],
      ],
    },
    metadata: {
      'mapbox:featureComponent': 'place-labels',
      'mapbox:group': 'Place labels, place-labels',
    },
    maxzoom: 10,
    filter: [
      'match',
      ['get', 'class'],
      'country',
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      'disputed_country',
      [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      false,
    ],
    type: 'symbol',
    source: 'mapbox-streets',
    id: 'country-label',
    paint: {
      'icon-opacity': [
        'step',
        ['zoom'],
        ['case', ['has', 'text_anchor'], 1, 0],
        7,
        0,
      ],
      'text-color': 'hsl(0, 0%, 95%)',
      'text-halo-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        'hsla(0, 5%, 0%, 0.75)',
        3,
        'hsl(0, 5%, 0%)',
      ],
      'text-halo-width': 1.25,
    },
    'source-layer': 'place_label',
  });
}

function removePlaceLabelLayers(map) {
  if (map.getLayer('admin-1-boundary-bg')) {
    map.removeLayer('admin-1-boundary-bg');
    map.removeLayer('admin-0-boundary-bg');
    map.removeLayer('admin-1-boundary');
    map.removeLayer('admin-0-boundary');

    map.removeLayer('admin-0-boundary-disputed');

    map.removeLayer('settlement-subdivision-label');
    map.removeLayer('settlement-major-label');
    map.removeLayer('settlement-minor-label');
    map.removeLayer('state-label');
    map.removeLayer('country-label');
  }
}
export {
  addBaseMapLayers,
  mapStyle,
  getTilesForCurrentBounds,
  locationObj,
  addPlaceLabelLayers,
  removePlaceLabelLayers,
  turnOffBaseMapLayers,
  turnOnBaseMapLayers,
};
