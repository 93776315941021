import { createContext, useReducer, useContext } from 'react';
import creditReducer from './reducer';

const initialState = {
  organizationId: '',
  expiryDate: null,
  availableCredits: 0,
  totalCredits: 0,

  isLoading: true,
  error: null,
};

const creditContext = createContext(initialState);

const UsageCreditProvider = (props) => {
  const [state, dispatch] = useReducer(creditReducer, initialState);

  return <creditContext.Provider value={{ state, dispatch }} {...props} />;
};

const useCreditContext = () => {
  const { state: creditState, dispatch: creditDispatch } =
    useContext(creditContext);

  return { creditState, creditDispatch };
};

export { useCreditContext, UsageCreditProvider };
