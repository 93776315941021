/* eslint-disable no-nested-ternary */
// ViewSubscriptions.js
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { listSubscriptions } from 'api/partialdr/subscriptions';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import NavBar from 'components/NavBar';
import { BusinessIcon, UserCircleIcon, LogoutIcon } from 'utils/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';
import signOut from 'helpers/signOut';
import { useAuthContextState, useAuthContext } from 'contexts/Auth';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import * as turf from '@turf/turf';
import SubscriptionTable from 'components/Subscriptions/SubscriptionTable';
import SidePanelMenu from 'components/SidePanelMenu';
import styles from './mapview.module.css';

const ViewSubscriptions = () => {
  const [_authState, authDispatch] = useAuthContext();
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(14);

  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',

        Cell: ({ row }) => {
          const bbox = row?.original?.bbox
            ? turf.bbox(row?.original?.bbox)
            : [];

          const subscriptionName = row?.original?.name ?? 'No Name';
          const encodedName = encodeURIComponent(subscriptionName);
          return (
            <Link
              to={`/quick-dr/subscriptions/${
                row.original.id
              }?name=${encodedName}&bbox=${bbox.join(',')}`}
            >
              {subscriptionName}
            </Link>
          );
        },
      },
      {
        Header: 'Start Date',
        accessor: 'startTime',
        Cell: ({ row }) => row?.original?.startTime?.split('T')?.[0] ?? '',
      },
      {
        Header: 'End Date',
        accessor: 'endTime',
        Cell: ({ row }) => row?.original?.endTime?.split('T')?.[0] ?? '',
      },
      {
        Header: 'Cloud Coverage',
        accessor: 'props.problematic_area_percentage',
        Cell: ({ value }) => `≤${Math.round((value || 0.4) * 100)}%`,
      },
      {
        Header: 'Last Processed Date',
        accessor: 'lastProcessedDate',
        Cell: ({ row }) => row?.original?.lastProcessedDate ?? '',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => row?.original?.status ?? '',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ row }) =>
          row?.original?.createdAt
            ? new Date(row?.original?.createdAt).toLocaleString()
            : '',
      },
    ],
    []
  );

  const { isLoading, error, data } = useQuery(
    ['subscriptions', pageIndex, pageSize],
    async () => {
      const subscriptionList = await listSubscriptions({
        page: pageIndex,
        limit: pageSize,
      });

      if (Array.isArray(subscriptionList)) {
        return subscriptionList;
      }

      return [];
    },
    {
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
    }
  );

  return (
    <div>
      <NavBar Title="Partial DR Subscriptions" />
      <div className={styles.dashboardRoot}>
        <SidePanelMenu />
        <div className={styles.viewSubscriptions}>
          <div className={styles.pdrListTitle}>
            <Typography variant="h5" fontWeight={700}>
              My Orders
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                // console.log('clicked');
                history.push('/quick-dr/subscriptions/create');
              }}
            >
              Create New Order
            </Button>
          </div>
          <SubscriptionTable
            columns={columns}
            data={data}
            error={error}
            isLoading={isLoading}
            setQueryPageIndex={setPageIndex}
            setQueryPageSize={setPageSize}
            queryPageIndex={pageIndex}
            queryPageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSubscriptions;
