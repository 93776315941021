import { useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Typography, FormControl } from '@mui/material';

import SignUpContainer from 'components/SignUpContainer';
import { useAuthContext } from 'contexts/Auth';
import { confirmUserAsync, resendConfirmationAsync } from 'api/cognito';
import {
  clearErrors,
  startConfirmUser,
  confirmUserSuccess,
  confirmUserFail,
  startResendConfirmationCode,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFail,
} from 'contexts/Auth/actions';
import styles from './authentication.module.css';

const ConfirmUser = () => {
  const [authState, authDispatch] = useAuthContext();
  const location = useLocation();
  const history = useHistory();

  const validationSchema = Yup.object({
    code: Yup.string().required('Required'),
  });

  const handleConfirmUser = async (formData, formikOptions) => {
    formikOptions.setSubmitting(true);
    authDispatch(startConfirmUser());
    const { code } = formData;
    try {
      const isUserConfirmed = await confirmUserAsync(
        location.state.email,
        code
      );
      if (typeof isUserConfirmed !== 'boolean' && isUserConfirmed.error) {
        authDispatch(confirmUserFail(isUserConfirmed.message));
      } else {
        authDispatch(confirmUserSuccess());
        history.push('/sign-in');
      }
    } catch (error) {
      authDispatch(confirmUserFail(error.message));
    }
  };

  const handleResendConfirmationCode = async () => {
    authDispatch(startResendConfirmationCode());
    try {
      const response = await resendConfirmationAsync(location.state.email);

      if (response.error) {
        authDispatch(resendConfirmationCodeFail(response.error.message));
      } else {
        authDispatch(resendConfirmationCodeSuccess());
      }
    } catch (error) {
      authDispatch(resendConfirmationCodeFail(error.message));
    }
  };

  // clear previous auth errors
  useEffect(() => authDispatch(clearErrors()), []);

  // redirect is user is logged in or email is not provided
  useEffect(() => {
    if (!location.state || !location.state.email) {
      history.push('/sign-in');
    }
  }, []);

  return (
    <SignUpContainer>
      <h2>Welcome to Digifarm</h2>
      <Typography color="text.secondary">
        Confirmation code has been sent to your email
      </Typography>
      <Typography color="text.secondary" marginBottom={2}>
        Please provide the code to proceed
      </Typography>

      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirmUser}
      >
        {(formik) => (
          <FormControl>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                {...formik.getFieldProps('code')}
                className={styles.signUpInput}
                label="Confirmation Code"
                variant="filled"
                on="true"
                fullWidth
              />
              <ErrorMessage
                name="name"
                component="div"
                className={styles.validationError}
              />
              {authState.confirmUserError && (
                <div className={styles.validationError}>
                  {authState.confirmUserError}
                </div>
              )}
              {authState.resendConfirmationCodeError && (
                <div className={styles.validationError}>
                  {authState.resendConfirmationCodeError}
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                disabled={
                  !!formik.isSubmitting || !formik.dirty || !formik.isValid
                }
              >
                Confirm User
              </Button>
              <Button
                variant="contained"
                color="text"
                size="large"
                fullWidth
                onClick={() => handleResendConfirmationCode()}
              >
                Resend Code
              </Button>
            </Form>
          </FormControl>
        )}
      </Formik>
      <Typography marginTop={2} color="text.grey">
        Already have an account? <Link to="/sign-in">Sign In</Link>
      </Typography>
    </SignUpContainer>
  );
};

export default ConfirmUser;
