import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useCreditContext } from 'contexts/Credit';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import truncateToken from 'utils/utils';
import styles from './CreditPanel.module.css';

const calculatePercentageUsed = (availableCredits, totalCredits) => {
  if (totalCredits === 0) {
    return 0;
  }
  const usedCredits = totalCredits - availableCredits;
  const percentageUsed = (usedCredits / totalCredits) * 100;
  return Math.round(percentageUsed);
};

function CreditPanel() {
  const {
    creditState: {
      organizationId,
      availableCredits,
      totalCredits,
      isLoading,
      error,
    },
  } = useCreditContext();

  const formattedOrganizationId = truncateToken(organizationId); // Shows last 4 characters
  const creditUsage = calculatePercentageUsed(availableCredits, totalCredits);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        severity="warning"
        sx={{
          '& .MuiAlert-icon': {
            alignItems: 'center',
          },
        }}
      >
        {error}
      </Alert>
    );
  }
  return (
    <Box className={styles.container}>
      <Box className={styles.orgIdContainer}>
        <Typography
          variant="body1"
          className={styles.orgIdLabelText}
          sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 500 }}
        >
          Organization ID:
        </Typography>
        <Box
          component="span"
          className={styles.orgIdValue}
          sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 500 }}
        >
          ****{formattedOrganizationId}
        </Box>
      </Box>

      <Box>
        <Box className={styles.creditsContainer}>
          <Typography
            variant="body1"
            className={styles.creditsLabel}
            sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 500 }}
          >
            Available Credits:
          </Typography>
          <Typography variant="body1">
            <Box
              component="span"
              className={styles.creditsValue}
              sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 500 }}
            >
              € {availableCredits.toLocaleString()}
            </Box>
          </Typography>
        </Box>
        <LinearProgress variant="determinate" value={creditUsage} />
      </Box>
    </Box>
  );
}

export default CreditPanel;
