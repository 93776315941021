import { Button, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SignUpContainer from 'components/SignUpContainer';
import styles from './authentication.module.css';

const ResetPassword = () => (
  <SignUpContainer>
    <h2>Let’s create new password </h2>

    <Typography color="text.secondary" marginBottom={2}>
      After you reset your password you need to create new one.
    </Typography>

    <TextField
      label="Your new Password"
      variant="filled"
      type="email"
      fullWidth
      className={styles.signUpInput}
    />

    <Button variant="contained" color="primary" size="large" fullWidth>
      Set New Password
    </Button>
    <Typography marginTop={10} color="text.grey">
      <Link to="/sign-up">Go back to Sign In</Link>
    </Typography>
  </SignUpContainer>
);

export default ResetPassword;
