import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
} from '@mui/material';

import SignUpContainer from 'components/SignUpContainer';
import { useAuthContext } from 'contexts/Auth';
import { registerWithEmailPasswordAsync } from 'api/cognito';
import {
  clearErrors,
  startSignUp,
  signUpSuccess,
  signUpFailure,
} from 'contexts/Auth/actions';
import styles from './authentication.module.css';

const SignUp = () => {
  const [authState, authDispatch] = useAuthContext();
  const history = useHistory();

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required'),
    termsOfService: Yup.bool()
      .required('The terms of service must be accepted')
      .oneOf([true], 'The terms of service must be accepted'),
  });

  const handleSubmit = async (formData, formikOptions) => {
    formikOptions.setSubmitting(true);
    authDispatch(startSignUp());
    const { name, email, password } = formData;
    try {
      const response = await registerWithEmailPasswordAsync(
        name,
        email,
        password
      );
      if (response.error) {
        authDispatch(signUpFailure(response.error.message));
      } else {
        authDispatch(signUpSuccess(response.user));
        history.push('/confirm-user', { email });
      }
    } catch (error) {
      authDispatch(signUpFailure(error.message));
    }
  };

  // clear previous auth errors
  useEffect(() => authDispatch(clearErrors()), []);

  return (
    <SignUpContainer>
      <h2>Welcome to Digifarm</h2>
      <Typography color="text.secondary" marginBottom={2}>
        Sign Up with your email:
      </Typography>

      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          termsOfService: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <FormControl>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                {...formik.getFieldProps('name')}
                className={styles.signUpInput}
                label="Name"
                variant="filled"
                on="true"
                fullWidth
              />
              <ErrorMessage
                name="name"
                component="div"
                className={styles.validationError}
              />
              <TextField
                {...formik.getFieldProps('email')}
                className={styles.signUpInput}
                type="email"
                label="Email"
                variant="filled"
                fullWidth
              />
              <ErrorMessage
                name="email"
                component="div"
                className={styles.validationError}
              />
              <TextField
                {...formik.getFieldProps('password')}
                className={styles.signUpInput}
                type="password"
                label="Password"
                variant="filled"
                fullWidth
              />
              <ErrorMessage
                name="password"
                component="div"
                className={styles.validationError}
              />
              <FormControlLabel
                className={styles.termsAndConditions}
                control={
                  <Checkbox {...formik.getFieldProps('termsOfService')} />
                }
                label={
                  <Typography
                    className={styles.termsAndConditionsLabel}
                    fontSize={12}
                  >
                    {' '}
                    I have read and agree to the Digifarm{' '}
                    <Link to="/">Terms of Service</Link> and{' '}
                    <Link to="/">Privacy Policy</Link>.
                  </Typography>
                }
              />
              <ErrorMessage
                name="termsOfService"
                component="div"
                className={styles.validationError}
              />
              {authState.signUpError && (
                <div className={styles.validationError}>
                  {authState.signUpError}
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                disabled={
                  !!formik.isSubmitting || !formik.dirty || !formik.isValid
                }
              >
                Sign Up
              </Button>
            </Form>
          </FormControl>
        )}
      </Formik>
      <Typography marginTop={2} color="text.grey">
        Already have an account? <Link to="/sign-in">Sign In</Link>
      </Typography>
    </SignUpContainer>
  );
};

export default SignUp;
