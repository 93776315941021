import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles'; // Import makeStyles from @mui/styles

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    padding: '16px',
    borderRadius: '8px',
    textAlign: 'center',
    outline: 'none',
  },
}));

const AoiChangeConfirmationModal = ({ open, onConfirm, onCancel }) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modal}>
      <div className={classes.modalContainer}>
        <Typography variant="h6" id="modal-title">
          Confirm AOI Change
        </Typography>
        <Typography variant="body1" id="geojson-instruction-description">
          Are you sure you want to change the AOI selection? This action will
          clear the current selection.
        </Typography>

        <Button
          onClick={onCancel}
          color="primary"
          variant="contained"
          style={{ marginTop: '20px', marginLeft: '10px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          style={{ marginTop: '20px', marginLeft: '10px' }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default AoiChangeConfirmationModal;
