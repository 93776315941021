import { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Amplify from 'aws-amplify';
import AmplifyConfig from 'constants/amplify';

import SignUp from 'pages/Authentication/SignUp';
import ConfirmUser from 'pages/Authentication/ConfirmUser';
import SignIn from 'pages/Authentication/SignIn';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import ResetPassword from 'pages/Authentication/ResetPassword';
import Dashboard from 'pages/Dashboard';
import TokenDashboard from 'pages/TokenDashboard';
import ViewSubscriptions from 'pages/QuickDR/ViewSubscriptions';

import AuthRoute from 'components/AuthRoute';

import { loadCurrentUserAsync } from 'api/cognito';
import { useAuthContext } from 'contexts/Auth';
import {
  startLoadCurrentUser,
  loadCurrentUserSuccess,
  loadCurrentUserFail,
} from 'contexts/Auth/actions';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './App.css';
import QuickDRMapView from 'pages/QuickDR/MapView';
import ViewSubscriptionImageries from 'pages/QuickDR/ViewSubscriptionImageries';
import { getActiveTokenAndCredits } from 'api/partialdr/subscriptions';

import { useCreditContext } from 'contexts/Credit';
import {
  setCredit,
  setCreditError,
  setCreditLoadingState,
} from 'contexts/Credit/action';
import ChatFlow from 'constants/hubspot';

Amplify.configure(AmplifyConfig);
const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    caption: {
      fontWeight: 500,
      color: '#949EA7',
    },
  },
  palette: {
    primary: {
      light: '#CCEFE3',
      main: '#01B075',
      dark: '#017951',
      contrastText: '#fff',
    },
    text: {
      primary: '#1D2023',
      secondary: '#949EA7',
      grey: '#636A72',
    },
    secondary: {
      main: '#01B075',
    },
    error: {
      main: '#F84A4A',
    },
    common: {
      black: '#1D2023',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontWeight: 600,
      },
    },
  },
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});

const App = () => {
  const [authState, authDispatch] = useAuthContext();
  const { creditDispatch } = useCreditContext();
  const location = useLocation();

  useEffect(() => {
    authDispatch(startLoadCurrentUser());

    const loadCurrentSession = async () => {
      try {
        const user = await loadCurrentUserAsync();
        if (user) {
          authDispatch(loadCurrentUserSuccess(user.attributes));
        } else {
          authDispatch(loadCurrentUserFail());
        }
      } catch (err) {
        authDispatch(
          loadCurrentUserFail(err.message || 'Something went wrong')
        );
      }
    };
    loadCurrentSession();
  }, []);

  useEffect(() => {
    if (authState.user) {
      creditDispatch({
        type: setCreditLoadingState,
        payload: {
          isLoading: true,
        },
      });

      getActiveTokenAndCredits()
        .then((result) => {
          creditDispatch({
            type: setCredit,
            payload: result,
          });
        })
        .catch((err) => {
          creditDispatch({
            type: setCreditError,
            payload: {
              error: err?.response?.data?.message,
            },
          });
        })
        .finally(() => {
          creditDispatch({
            type: setCreditLoadingState,
            payload: {
              isLoading: false,
            },
          });
        });
    }
  }, [location, authState]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {!authState.isValidatingSession ? (
          <div className="App">
            <Switch>
              <Route path="/sign-in" component={SignIn} />
              <Route path="/sign-up" component={SignUp} />
              <Route path="/confirm-user" component={ConfirmUser} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />

              <AuthRoute
                authUser={authState.user}
                component={Dashboard}
                path="/dashboard"
              />

              <AuthRoute
                authUser={authState.user}
                component={QuickDRMapView}
                exact
                path="/quick-dr/subscriptions/create"
              />

              <AuthRoute
                exact
                authUser={authState.user}
                component={ViewSubscriptionImageries}
                path="/quick-dr/subscriptions/:subscriptionId"
              />

              <AuthRoute
                exact
                authUser={authState.user}
                component={ViewSubscriptions}
                path="/quick-dr/subscriptions"
              />

              <AuthRoute
                authUser={authState.user}
                component={TokenDashboard}
                path="/token-management"
              />

              <Redirect to="/sign-in" />
            </Switch>
            <ChatFlow className="hubspot-chatflow" />
          </div>
        ) : (
          <div className="loading">
            <img alt="logo" src="/logo192.png" style={{ width: '50px' }} />
          </div>
        )}
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
