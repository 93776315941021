/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTable, usePagination } from 'react-table';

import { makeStyles } from '@mui/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

import MultiDropdownButton from 'components/MultiDropdownButton';
import DownloadButton from 'components/DownloadButton';

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  thead: {
    backgroundColor: '#f2f2f2',
  },
  th: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    '&:first-child': {
      paddingLeft: '16px',
    },
    '&:last-child': {
      paddingRight: '16px',
    },
  },
  td: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  tbodyTr: {
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  checkbox: {
    marginRight: '8px',
  },
  queueRow: {
    backgroundColor: '#dcd9ff', // Light blue-green color
  },
  processingRow: {
    backgroundColor: '#acf7f5', // Light green color
  },
  processedRow: {
    backgroundColor: '#E5F7F1', // Spring green color
  },
  failedRow: {
    backgroundColor: '#f9acad', // Light coral color
  },
  greyRow: {
    backgroundColor: '#ecf0f1',
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { page, rowsPerPage, onPageChange, currentPageSize } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={currentPageSize < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

const SentinelAvailableImageriesTable = ({
  sentinelImagery = [],
  renderTCI,
  setQueryPageIndex,
  setQueryPageSize,
  queryPageIndex,
  queryPageSize,
  isLoading,
  error,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => row?.original?.date?.split('T')?.[0] ?? '',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => row?.original?.status ?? '',
      },
      {
        Header: 'Render',
        Cell: ({ row }) => (
          <MultiDropdownButton
            disabled={row?.original?.status !== 'PROCESSED'}
            options={['RGB', 'EVI', 'NDVI']}
            handleClick={(option) => {
              renderTCI(row.original, option);
            }}
          />
        ),
      },
      {
        Header: 'Download',
        Cell: ({ row }) => (
          <DownloadButton
            url={row?.original?.signedUrl}
            disabled={row?.original?.status !== 'PROCESSED'}
          />
        ),
      },
    ],
    []
  );

  const classes = useStyles();

  const useControlledState = (state) =>
    React.useMemo(
      () => ({
        ...state,
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      }),
      [state, queryPageIndex, queryPageSize]
    );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: sentinelImagery,
        useControlledState,
        manualPagination: true,
      },
      usePagination
    );

  const handleChangePage = (event, newPage) => {
    setQueryPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryPageSize(parseInt(event.target.value, 10));
    setQueryPageIndex(0);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table {...getTableProps()} className={classes.table}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              className={classes.thead}
            >
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.th}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.td}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.td}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" component="div">
                    {error?.error ?? 'Server Error.'}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={
                    row.original.status === 'QUEUED'
                      ? classes.queueRow
                      : row.original.status === 'PROCESSING'
                      ? classes.processingRow
                      : row.original.status === 'PROCESSED'
                      ? classes.processedRow
                      : row.original.status === 'FAILED'
                      ? classes.failedRow
                      : classes.greyRow
                  }
                >
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()} className={classes.td}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.td}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" component="div">
                    No data found
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              colSpan={columns.length}
              rowsPerPage={queryPageSize}
              page={queryPageIndex}
              count={-1}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={(props) => (
                <TablePaginationActions
                  {...props}
                  currentPageSize={rows.length}
                />
              )}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SentinelAvailableImageriesTable;
