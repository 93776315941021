import React, { useRef, useState, useEffect, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxCompare from 'mapbox-gl-compare';
import { Button, IconButton, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { store } from 'contexts/Map/store';
import * as turf from '@turf/turf';

// import turf, { bboxPolygon, difference } from 'turf';
// import * as turf from '@turf/turf';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import DrawAssistedRectangle from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode';
import tilebelt from '@mapbox/tilebelt';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  // LineGlobeIcon,
} from '@mui/icons-material';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  setPoint,
  setPolygon,
  setTile,
  setFieldId,
  setTiles,
  setAPI,
  setDrawMode,
  setDRResult,
} from 'contexts/Map/action';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { LineGlobeIcon } from 'icons';
import MAXZOOM from 'constants/map-constants';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2lyaXNocGFsbGFnYXR0aSIsImEiOiJja2h6NGVuc20wYndjMnlxaDExbzlsa3B2In0.e94ZfcoPleAA2nqjeX10ag';

let heightOfTheWindow = window.innerHeight;

window.addEventListener('resize', () => {
  heightOfTheWindow = window.innerHeight;
});

const useStyles = makeStyles({
  hoverDisplayContainer: {
    position: 'absolute',
    left: 60,
    top: 0,
    zIndex: 1,
    minHeight: 220,
    paddingLeft: 8,
    minWidth: 160,
    textAlign: 'left',
    color: '#fff',
  },
  shadowGenerator: {
    padding: 30,
    zIndex: 1,
    position: 'relative',
    top: -60,
    boxShadow: '-54px 72px 138px 62px rgba(0,0,0,0.85)',
  },
  hoverContent: {
    position: 'relative',
    zIndex: 2,
  },
  globeIconContainer: {
    position: 'absolute',
    right: 8,
    bottom: 12,
    zIndex: 1,
    height: 48,
    width: 48,
    '@media (max-width: 600px)': {
      bottom: 56,
    },
  },
  globebutton: {
    width: 48,
    height: 48,
  },
  iconRoot: {
    fill: 'none',
  },
  clearButtonContainer: {
    position: 'absolute',
    right: 60,
    bottom: 12,
    // display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
  },
  clearButton: {
    backgroundColor: '#1D2023',
    fontSize: 14,
  },
  drawerToggleContainer: {
    position: 'absolute',
    right: 8,
    top: '40vh',
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  collapseDrawerButton: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
    '&:hover': {
      color: '#999',
    },
  },
  collapseDrawerButtonMobile: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
  },
  drawerToggleMobileOpen: {
    position: 'absolute',
    right: 68,
    top: heightOfTheWindow - 340,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
  drawerToggleMobileClosed: {
    position: 'absolute',
    right: 68,
    bottom: 64,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
});

const CompareMap = (props) => {
  const l2aContainerRef = useRef(null);
  const DRContainerRef = useRef(null);
  const compareContainerRef = useRef(null);
  const mapRef = useRef(null);
  const classes = useStyles();

  const { setZoom, toggleRightDrawer, isRightDrawerOpen, bbox } = props;

  const {
    state: { drResult, renderOption },
    dispatch,
  } = useContext(store);

  const [map, setMap] = useState(null);
  const [l2aMapLoad, setL2aMapLoad] = useState(false);
  const [drMapLoad, setDrMapLoad] = useState(false);

  const [l2aMap, setL2aMap] = useState(null);
  const [drMap, setDrMap] = useState(null);
  const [draw, setDraw] = useState(null);
  const [basemap, setBasemap] = useState('satellite-streets-v11');

  const drawModeRef = useRef(null);
  // const [mapDrawMode, setMapDrawMode] = useState(dataLayer.drawMode);
  const [showClearButton, setShowClearButton] = useState(false);

  let onCreateListener;

  useEffect(() => {
    // console.log('map init');
    const bounds = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];
    // console.log('bounds', bounds);
    const center = turf.center(turf.bboxPolygon(bbox));

    // console.log(center.geometry.coordinates); // [longitude, latitude]
    const l2aLocalMap = new mapboxgl.Map({
      container: l2aContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: center.geometry.coordinates,
      zoom: 13,
      hash: true,
      maxZoom: MAXZOOM,
    });

    l2aLocalMap.fitBounds(bounds, {
      padding: 100,
    });

    // console.log('bbox', bbox, turf.bboxPolygon(bbox));

    const drLocalMap = new mapboxgl.Map({
      container: DRContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: center.geometry.coordinates,
      zoom: 13,
      hash: true,
      maxZoom: MAXZOOM,
    });

    drLocalMap.fitBounds(bounds, {
      padding: 100,
    });
    // const container = '#compare-container';

    const compareLocalMap = new MapboxCompare(
      l2aLocalMap,
      drLocalMap,
      compareContainerRef.current,
      {
        // Set this to enable comparing two maps by mouse movement:
        // mousemove: true,
        orientation: 'vertical',
      }
    );

    l2aLocalMap.on('load', () => {
      setL2aMapLoad(true);
    });

    drLocalMap.on('load', () => {
      setDrMapLoad(true);
    });

    compareLocalMap.on('load', () => {
      compareLocalMap.on('zoomend', (e) => {
        setZoom(compareLocalMap.getZoom());
      });
    });

    drLocalMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    drLocalMap.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        types: 'country,region,district,place,locality,postcode',
        collapsed: true,
        marker: {
          color: '#01B075',
        },
      })
    );

    setMap(compareLocalMap);
    setL2aMap(l2aLocalMap);
    setDrMap(drLocalMap);

    mapRef.current = compareLocalMap;

    // Clean up on unmount
    return () => {
      l2aLocalMap.remove();
      drLocalMap.remove();
      compareLocalMap.remove();
      setMap(null);
      setL2aMap(null);
      setDrMap(null);
      setL2aMapLoad(false);
      setDrMapLoad(false);
      dispatch({
        type: setDRResult,
        payload: {
          drResult: null,
        },
      });
    };
  }, [bbox]);

  const toggleBaseLayer = () => {
    if (basemap === 'satellite-streets-v11') {
      l2aMap.setStyle('mapbox://styles/mapbox/light-v11');
      drMap.setStyle('mapbox://styles/mapbox/light-v11');
      setBasemap('light-v11');
    } else {
      drMap.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      l2aMap.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      setBasemap('satellite-streets-v11');
    }
  };

  useEffect(() => {
    // console.log(drResult, 'drResilt here', l2aMapLoad, drMapLoad);
    if (drResult && l2aMapLoad && drMapLoad) {
      const {
        fileLocation,
        props: { L2A_URL },
      } = drResult;

      const render = {
        NDVI: 'rgbn_ndvi',
        EVI: 'rgbn_evi',
        RGB: 'rgbn_rgb',
      };

      const fileLocationURL = `https://rasters.digifarm.io/v1/rasters/file/{z}/{x}/{y}.png?token=c3b3ffa9-a0fd-4a01-abdd-168b675e8800&filename=${fileLocation}&render=${render[renderOption]}&disable_cache=true`;

      // console.log('fileLocation', fileLocation);
      // console.log('L2A_URL', L2A_URL);
      // console.log('fileLocationURL', fileLocationURL);

      // console.log('l2aMap', l2aMap);

      if (l2aMap && l2aMap.getSource('tileURL')) {
        l2aMap.removeLayer('tileURL');
        l2aMap.removeSource('tileURL');
      }

      if (l2aMap) {
        l2aMap.addSource('tileURL', {
          type: 'raster',
          tiles: [L2A_URL],
          tileSize: 256,
        });

        l2aMap.addLayer({
          id: 'tileURL',
          type: 'raster',
          source: 'tileURL',
          layout: { visibility: 'visible' },
        });
      }

      if (drMap && drMap.getSource('tileURL')) {
        drMap.removeLayer('tileURL');
        drMap.removeSource('tileURL');
      }

      if (drMap && fileLocation) {
        drMap.addSource('tileURL', {
          type: 'raster',
          tiles: [fileLocationURL],
          tileSize: 256,
        });

        drMap.addLayer({
          id: 'tileURL',
          type: 'raster',
          source: 'tileURL',
          layout: { visibility: 'visible' },
        });
      }

      if (l2aMap && l2aMap.getSource('tilemask')) {
        l2aMap.removeLayer('tilemasklayer');
        l2aMap.removeSource('tilemask');
      }

      if (drMap && drMap.getSource('tilemask')) {
        drMap.removeLayer('tilemasklayer');
        drMap.removeSource('tilemask');
      }

      if (l2aMap) {
        l2aMap.addSource('tilemask', {
          type: 'geojson',
          data: turf.bboxPolygon(bbox),
        });
        l2aMap.addLayer({
          id: 'tilemasklayer',
          type: 'line',
          source: 'tilemask',
          paint: {
            // 'fill-color': 'rgba(46, 204, 113,0.2)',
            'line-color': '#000000',
            'line-width': 2,
          },
        });
      }

      if (drMap) {
        drMap.addSource('tilemask', {
          type: 'geojson',
          data: turf.bboxPolygon(bbox),
        });

        drMap.addLayer({
          id: 'tilemasklayer',
          type: 'line',
          source: 'tilemask',
          paint: {
            // 'fill-color': 'rgba(46, 204, 113,0.2)',
            'line-color': '#000000',
            'line-width': 2,
          },
        });
      }
    }
  }, [drResult, bbox, l2aMap, drMap, l2aMapLoad, drMapLoad]);

  return (
    <div className="map-and-controls-container">
      <div id="compare-container" ref={compareContainerRef}>
        <div className="map-container" ref={l2aContainerRef} />
        <div className="map-container" ref={DRContainerRef} />
      </div>
      <div className={classes.globeIconContainer}>
        <Paper>
          <IconButton onClick={toggleBaseLayer} className={classes.globebutton}>
            <LineGlobeIcon classes={{ root: classes.iconRoot }} />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
};

export default CompareMap;
